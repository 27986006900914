<template lang="pug">
  .comparing-item(:class="themeClass")
    .comparing-item__title {{ title }}
    .comparing-item__description {{ description }}
      .comparing-item__image(:style="{'background-image': `url('${imageLink}')`}")
    .comparing-item__calculations
      .comparing-item__price {{ price }}
      .comparing-item__undertext {{ undertext }}
      .comparing-item__summary(v-if="summary") {{ summary }}

</template>

<script>
/* eslint-disable */

export default {
  props: ['theme', 'title', 'description', 'price', 'undertext', 'summary', 'image'],
  computed: {
    imageLink () {
      return require('../assets/comparings/'+this.image)
    },
    themeClass () {
      return this.theme === 'red' ? 'comparing-item--red' : ''
    }
  }
}
</script>

<style lang="sass">
.comparing-item
  display: flex
  flex-direction: column
  gap: 32px
  padding: 20px
  color: #FFF
  border-radius: 20px
  background-color: #383838
  text-align: left
  font-size: 20px
  overflow: hidden
  // box-shadow: 0 20px 24px rgb(0 0 0 / 15%)
  max-width: 470px

  @media (min-width: 640px)
    font-size: 16px
    flex: 42%

  &--red
    background-color: #ffb6b6
    color: #000

  &__title
    font-weight: 700
    font-size: 24px

  &__price
    background-color: #ffffff
    border-radius: 4px
    color: #000
    text-align: center
    padding: 16px 20px
    font-weight: 600

  &__calculations
    display: flex
    flex-direction: column
    gap: 20px

  &__description
    display: flex
    gap: 40px
    line-height: 32px
    align-items: center

  &__image
    margin-right: -64px
    min-width: 160px
    height: 200px
    background-repeat: no-repeat
    background-size: contain
    background-position: left center
    @media (min-width: 640px)
      height: 140px

</style>
