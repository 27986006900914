<template lang="pug">
  .constructor
    item-modal(v-if="$store.state.showItemModal")
    purchase-modal(v-if="$store.state.showPurchaseModal")
    transition(name="slide-fade")
      my-sizes-modal(v-if="$store.state.showMySizesModal")
    transition(name="slide-fade")
      add-item-modal(:empty-area-sum="emptyAreaSum"
          v-if="$store.state.showAddItemModal" @select="selectItem"
      )
    // .constructor__open-catalog(@click="clickOpenCatalog") Смотреть каталог вещей
      .constructor__open-catalog-icon
    div.constructor__grid(ref="grid" :style="{'opacity': defaultItemsAdded ? 1 : 0}")
    .constructor__buttons(v-if="!hideButtons")
      helper(
        v-if="!hideOnboarding"
        ref="helperEdit"
        @close="closeEditHelper"
      ) Нажмите, чтобы настроить комплект под себя, например:
        |  убрать все футболки и заполнить комплект только носками
      custom-button.constructor__edit-button(
        :theme="!$store.state.editMode && 'outline'"
        @click.native="clickEdit"
      ) {{ $store.state.editMode ? 'Сохранить изменения' : 'Редактировать комплект' }}
      custom-button(v-if="$store.state.editMode"
        theme="outline"
        @click.native="clickChangeSizes"
      ) Настроить размеры
      custom-button(
        theme="accent"
        anim="ripple"
        :disabled="isSomethingRemoved"
        @click.native="clickPurchase"
      ) {{ isSomethingRemoved ? 'Для заказа заполните комплект' : 'Заказать за 4990 ₽' }}
    .constructor__undertext Бесплатная доставка по Москве
</template>

<script>
/* eslint-disable */
import Card from '@/components/constructor/Card.vue';
import AddItemModal from '@/components/constructor/AddItemModal.vue';
import CustomButton from '@/components/CustomButton.vue';
import Muuri from 'muuri'
import Vue from 'vue'
import DefaultItems from './defaultItems';
import itemsDimensions from './itemsCategories';
import itemsTypes from './itemsTypes';
import ItemModal from '@/components/constructor/ItemModal';
import MySizesModal from '@/components/constructor/MySizesModal';
import PurchaseModal from '@/components/constructor/PurchaseModal';
import Helper from '@/components/Helper.vue';


const perhapsDimensions = {
  1: {w: 1, h: 1},
  4: {w: 2, h: 2},
  8: {w: 4, h: 2},
  2: {w: 1, h: 2},
  16: {w: 4, h: 4},
  24: {w: 4, h: 6},
}


export default {
  props: ['empty', 'hideButtons', 'hideOnboarding'],
  components: {
    ItemModal,
    Card,
    AddItemModal,
    MySizesModal,
    CustomButton,
    PurchaseModal,
    Helper
  },
  data() {
    return {
      grid: null,
      defaultItemsAdded: false
    }
  },
  methods: {
    closeEditHelper () {
      this.$event( 'close_constructor_editor_helper', {
        'event_category': 'onboarding'
      });
    },
    createItem(item, removed, index = -1) {
      const componentClass = Vue.extend(Card)
      const instance = new componentClass({
        propsData: { w: item.type.category.w, h: item.type.category.h },
        store: this.$store
      }).$mount()
      this.$refs.grid.appendChild(instance.$el)
      const muuriInstance = this.grid.add(instance.$el, {index: index})[0]
      // this.$store.commit('setMuuriId', { index, muuriId: muuriInstance._id})
      console.log(item.type.category.name)
      this.$store.commit('createItem', {
        selectedColorIndex: item.selectedColorIndex,
        selectedSizeIndex: item.type.category.name ? this.$store.state.categories[Object
          .keys(this.$store.state.categories)
          .find(key => this.$store.state.categories[key].name === item.type.category.name)
          ].defaultSizeIndex : 0,
        type: item.type,
        removed: !!removed,
        muuriId: muuriInstance._id
      })
      instance.$data.muuriId = muuriInstance._id
      instance.muuriInstance = muuriInstance
      instance.$parent = this
      muuriInstance.vueInstance = instance
    },
    deleteItems(muuriInstances) {
      muuriInstances.forEach(instance => instance.vueInstance.$destroy())
      this.grid.remove(muuriInstances, {removeElements: true})
      this.$store.commit('deleteItemsByMuuriIds', muuriInstances.reduce((accum, item) => [...accum, item._id], []))

    },
    selectItem({itemType, selectedColorIndex}) {
      // Если есть фигура с такими же размерамы - мы ее просто меняем

      // Смотрим, есть ли фигура с такими же размерами
      const similuarRemovedItemIndex = this.storeItems.findIndex(item => {
        return item.removed && item.type.category.w === itemType.category.w && item.type.category.h === itemType.category.h
      })

      if (similuarRemovedItemIndex > -1) {
        this.$store.commit('changeTypeColorAndUnmark', {type: itemType, index: similuarRemovedItemIndex, selectedColorIndex})
      } else {
        // Если нет фигуры, нужно удалить наиболее приближенную по размерам фигуру
        // , добавить пердмет и добавить removed фигуру, чтобы заполнить оставшееся прост-во
        const selectedItemArea = itemType.category.w * itemType.category.h

        const nearestBySize = this.storeItems.reduce((nearestBySize, item, index) => {
          const itemArea = item.type.category.w * item.type.category.h
          if (itemArea > selectedItemArea && item.removed && itemArea < nearestBySize.size) {
            nearestBySize.size = itemArea
            nearestBySize.index = index
            nearestBySize.muuriId = item.muuriId
          }
          return nearestBySize

        }, {index: null, size: 999, muuriId: null})

        // Пространство, которое останется после удаления фигуры и добавления предмета
        let areaToFill = nearestBySize.size - selectedItemArea
        this.deleteItems([this.items.find(itemInGrid => itemInGrid._id === nearestBySize.muuriId)])
        this.createItem({type: itemType, selectedColorIndex}, false, 0)

        while (areaToFill > 0) {
          // const nearestBySizePerhapsDimensions =
          const perhapsFigureAreas = Object.keys(perhapsDimensions)
          perhapsFigureAreas.forEach((key, index) => {
            if (key <= areaToFill && (!perhapsFigureAreas[index + 1] || perhapsFigureAreas[index + 1] > areaToFill)) {
              areaToFill -= key
              this.createItem({type: {category: {w: perhapsDimensions[key].w, h: perhapsDimensions[key].h}}}, true)
            }
          })
        }





      }

      // this.createItem({type: itemType, selectedColorIndex})
    },
    markItemAsRemoved (id) {
      this.$store.commit('markItemAsRemoved', id)
      this.checkAndCombine()
    },
    checkLayoutAndFix () {
      if (this.grid._layout.height > 310) {
        // const sortedItems = this.grid.getItems().reduce((sortedItems, item) => {
        //   if (item.vueInstance.h >= 2 && item.vueInstance.w >= 2)
        //     sortedItems.unshift(item)
        //   else
        //     sortedItems.push(item)
        //   return sortedItems
        // }, [])
        const sortedItems = this.grid.getItems().sort((a, b) => {
          const areaA = a.vueInstance.h * a.vueInstance.w;
          const areaB = b.vueInstance.h * b.vueInstance.w;
          return areaA < areaB ? 1 : -1
        })
        this.grid.sort(sortedItems)
      }
    },
    checkAndCombine (removedItems = this.storeItems.filter(item => item.removed), recursive) {
      if (removedItems.length > 1) {
        const removedAreaSum = removedItems.reduce((accum, item) => {
          return accum += item.type.category.w * item.type.category.h
        }, 0)

        if (perhapsDimensions[removedAreaSum]) {

          let muuriInstancesToDelete = []
          removedItems.forEach(item => {
            muuriInstancesToDelete.push(this.items.find(itemInGrid => {
              return itemInGrid._id === item.muuriId
            }))
          })

          // const firstRemovedItemIndex = this.$store.state.items.findIndex(item => item.muuriId === removedItems[0].muuriId)
          const firstRemovedItemIndex = this.grid.getItems().findIndex(item => item._id === removedItems[0].muuriId)
          this.deleteItems(muuriInstancesToDelete)
          const dimensions = perhapsDimensions[removedAreaSum];

          // getting first removed item index
          this.createItem({type: {category: {w: dimensions.w, h: dimensions.h}}}, true, firstRemovedItemIndex)

        } else if (removedAreaSum % 2 === 0 && removedItems.length > 2) {

          const largestItemIndex = removedItems.reduce((accum, item, index) => {
            if (item.type.category.w * item.type.category.h > accum.area) {
              accum.area = item.type.category.w * item.type.category.h
              accum.index = index
            }
            return accum
          }, {area: 0, index: null}).index

          removedItems.splice(largestItemIndex, 1)

          this.checkAndCombine(removedItems, true)
          // const removedItemsWithoutLargest =
        }
      }
    },
    clickOpenCatalog () {
      this.$store.commit('setShowAddItemModal', true)
    },
    clickEdit() {
      if (this.$store.state.editMode) {
        // save
        this.$event( 'click_save', {
          'event_category': 'constructor'
        });
      } else {
        if (!this.hideOnboarding) {
          this.$refs.helperEdit.show = false
        }
        this.$emit('clickEdit')
        this.$event( 'click_edit', {
          'event_category': 'constructor'
        });
      }
      this.$store.commit('setEditMode', !this.$store.state.editMode)
    },
    clickPurchase() {
      this.$store.commit('setShowPurchaseModal', true)
      this.$emit('purchase')
    },
    clickChangeSizes () {
      this.$store.commit('setShowMySizesModal', true)
      this.$event( 'click_set_sizes', {
        'event_category': 'constructor'
      });
    }
  },
  computed: {
    items() {
      return this.grid ? this.grid.getItems() : null
    },
    storeItems() {
      return this.$store.state.items
    },
    emptyAreaSum() {
      return this.storeItems.filter(item => item.removed).reduce((accum, item) => {
        return accum += item.type.category.w * item.type.category.h
      }, 0)
    },
    isSomethingRemoved () {
      return this.storeItems.findIndex(item => {
        return item.removed
      }) > -1
    }
  },
  mounted() {
    this.grid = new Muuri('.constructor__grid', {
      dragEnabled: true,
      dragSort: true,
      dragStartPredicate: {
        distance: 5,
        delay: 100
      },
      dragSortPredicate: {
        threshold: 50,
        action: 'swap',
        migrateAction: 'swap',
      },
      layout: {
        fillGaps: true,
        horizontal: false,
        alignRight: false,
        alignBottom: false,
        rounding: true,
      },
    });

    if (this.empty) {
      this.createItem({type: {category: {w: 4, h: 4}}}, true)
    } else {
      DefaultItems.forEach(defaultItem => {
        defaultItem.type = itemsTypes.find(item => item.id === defaultItem.typeId)
        defaultItem.selectedSizeIndex = this.$store.state.categories[Object
          .keys(this.$store.state.categories)
          .find(key => this.$store.state.categories[key].name === defaultItem.type.category.name)
          ].defaultSizeIndex
        this.createItem(defaultItem)
      })
    }




    this.grid.on('layoutEnd', () => {
      this.defaultItemsAdded = true
      this.checkLayoutAndFix()

    });

    this.grid.on('dragReleaseEnd', () => {
      this.checkLayoutAndFix()
    });

    // this.grid.on('dragReleaseEnd', function (item, event) {
    //   event.stopPropagation()
    //   event.preventDefault()
    // });

  },
};
</script>

<style lang="sass">

.constructor
  background: #383838
  padding: 14px
  border-radius: 12px
  max-width: 414px
  min-width: 310px
  width: 100%

  &__open-catalog
    color: #fff
    font-size: 16px
    margin-bottom: 12px
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    gap: 12px
    line-height: 24px

    &-icon
      width: 15px
      height: 14px
      background-image: url('~@/assets/click.svg')
      background-size: cover

  &__grid
    position: relative
    width: 100%
    max-width: 414px
    margin: 0 auto
    // min-height: 456px
    transition: opacity .5s

  &__buttons
    padding: 0 6px 6px 6px
    margin-top: 26px
    display: flex
    flex-direction: column
    gap: 16px
    position: relative

  &__undertext
    color: #ffffff
    font-size: 16px
    margin-top: 12px
    margin-bottom: 8px
    text-align: center





/*.item.blue .custom-content {*/
/*    border-color: #0caaf5;*/
/*    color: #0caaf5;*/
/*}*/
/*.item.red .custom-content {*/
/*    border-color: #f54487;*/
/*    color: #f54487;*/
/*}*/
/*.item.green .custom-content {*/
/*    border-color: #00de73;*/
/*    color: #00de73;*/
/*}*/

/*@media (max-width: 877px) {*/
/*    .item {*/
/*        width: calc(33.33% - 11px);*/
/*        height: calc(33.33vw - 11px);*/
/*    }*/
/*    .item.w2 {*/
/*        width: calc(33.33% - 11px);*/
/*    }*/
/*    .item.h2 {*/
/*        height: calc(33.33vw - 11px);*/
/*    }*/
/*}*/
/*@media (max-width: 640px) {*/
/*    .item {*/
/*        width: calc(50% - 10px);*/
/*        height: calc(50vw - 10px);*/
/*    }*/
/*    .item.w2 {*/
/*        width: calc(50% - 10px);*/
/*    }*/
/*    .item.h2 {*/
/*        height: calc(50vw - 10px);*/
/*    }*/
/*}*/

</style>
