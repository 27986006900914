<template lang="pug">
  .reviews(track-viewing="reviews")
    .reviews__left-side
      review(
        name="Мирон"
        text="Распаковал и увидел письмо от ребят с просьбой оставить отзыв 😁 Оставляю! Минусы: Мне не хватило коротких носков в конструкторе. Еще я бы добавил что-то вроде худаков и т.п. Надеюсь добавите.\n" +
        "Плюсы: вещи в конструкторе действительно дешевле и хорошего качества, все померил, все приятное на ощупь. Еще очень круто, что есть свободные боксеры крутых расцветок, не везде они продаются. Буду следить за вами) Покупкой доволен "
        photo="miron.jpg"
        instagram="miron.dobrovsky"
      )
      review(
        name="Илья"
        text="Обычно закупаюсь в юникло, решил вот попробовать что-то новенькое. Коробка пришла, ребят, качество реально топ 🙏 🙏 Единственное, хотелось бы на следующий раз каких-нибудь новых расцветок"
        photo="ilya.jpg"
        instagram="620_x"
      )
    .reviews__right-side
      review(
        name="Глеб"
        text="Сомневался на счет размеров, но подкупила бесплатная доставка и возврат)) Честно говоря не люблю мотаться по магазинам и давно искал что-то подобное. Взял regular футболки на пробу и 8 носков. Все отлично!!"
        photo="gleb.jpg"
        instagram="_matveichev"
      )
      review(
        name="Максим"
        text="Только-только забрал в пикпоинте вторую послыку. Наверное мне очень повезло, что в каталоге почти все вещи мне заходят, даже красные носки 😝. После двух месяцев могу сказать, что вещи реально держат форму и круто сидят. Ребят, очень надеюсь, что расширите ассортимент, я брал и свободные и обычные футболки, хочется что-то новенькое"
        photo="maxg.jpg"
        instagram="maksim_161_"
      )

</template>

<script>
/* eslint-disable */
import Review from "@/components/Review.vue";


export default {
  components: {
    Review
  }
}
</script>

<style scoped lang="sass">
.reviews
  display: flex
  gap: 80px
  @media (max-width: 800px)
    gap: 40px
    flex-direction: column

  &__left-side
    @media (min-width: 800px)
      padding-top: 80px

  &__left-side, &__right-side
    display: flex
    flex-direction: column
    gap: 80px
    width: 100%
    @media (max-width: 800px)
      flex-direction: column
      gap: 40px



</style>
