<template lang="pug">
 .additional-modal
   .additional-modal__window
     .additional-modal__header Добавьте предметы в комплект
     .additional-modal__content
       .additional-modal__category(v-for="(category, index) in avaliableCategoriesWithColors"
         :key="index"
       )
         .additional-modal__category-name {{ category.itemType.name }}
         .additional-modal__colors
          .additional-modal__color.item(v-for="(color, colorIndex) in category.colors"
            :key="colorIndex"
            :class="[`h${category.h}`, `w${category.w}`]"
            @click="clickItem(colorIndex, category.itemType)"
          )
            cloth(:img-name="color.thumb")

     custom-button(@click.native="clickHideModal") Свернуть
</template>

<script>

/* eslint-disable */
import CustomButton from '@/components/CustomButton.vue';
import Cloth from '@/components/constructor/Cloth.vue';
import itemsTypes from './itemsTypes';

export default {
  components: {
    CustomButton,
    Cloth
  },
  computed: {
    avaliableCategoriesWithColors () {
      return itemsTypes.reduce((avaliableCategoriesWithColors, item) => {
        if (item.category.w * item.category.h <= this.emptyAreaSum && !item.disabled)
          avaliableCategoriesWithColors = [...avaliableCategoriesWithColors, {...item.category, itemType: item, colors: item.colors}]
        return avaliableCategoriesWithColors
      }, []).sort((a, b) => {
        console.log(a)
        return a.itemType.sort > b.itemType.sort ? 1 : -1
      })
    }
  },
  watch: {
    avaliableCategoriesWithColors (value) {
      if (value.length === 0)
        this.$store.commit('setShowAddItemModal', false)
    }
  },
  methods: {
    clickHideModal () {
      this.$store.commit('setShowAddItemModal', false)
    },
    clickItem (colorIndex, itemType) {
      this.$emit('select', {selectedColorIndex: colorIndex, itemType})
      this.$event( 'add_item', {
        'event_category': 'constructor',
        'event_label': `${itemType.name} ${itemType.colors[colorIndex].name}`
      });
    }
  },
  props: ['emptyAreaSum']
};
</script>

<style scoped lang="sass">
.additional-modal
  position: fixed
  bottom: 0
  left: 0
  right: 0
  background: #fff
  padding: 20px
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.25)
  border-radius: 12px 12px 0 0
  overflow: hidden
  z-index: 4
  transition: all 0.5s ease-out
  text-align: center

  @media (min-width: 530px)
    margin-left: auto
    margin-right: 50%
    max-width: 400px


  &__header
    color: #8F8F8F

  &__color
    position: relative
    cursor: pointer
    box-shadow: 4px 4px 8px #0000000d

  &__colors
    display: flex
    flex-wrap: wrap
    padding-bottom: 12px

  &__content
    max-height: 280px
    overflow-y: scroll
    margin: 20px 0

  &__category-name
    font-size: 16px
    margin-bottom: 12px

  &__category:not(:first-child)
    margin-top: 12px


</style>
