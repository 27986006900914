<template lang="pug">
  .benefit
    .benefit__number-block
      .benefit__number {{ number }}
      .benefit__bg-element
    .benefit__text
      slot

</template>

<script>
/* eslint-disable */
export default {
  props: ['number']
};
</script>

<style scoped lang="sass">
.benefit
  display: flex
  align-items: center
  margin: 32px 0

  &__bg-element
    width: 48px
    height: 32px
    background-image: url('~@/assets/benefit-pattern.jpg')
    border-bottom-right-radius: 32px
    border-top-right-radius: 32px

  &__number-block
    position: relative
    margin-right: 24px

  &__number
    position: absolute
    width: 48px
    font-size: 24px
    font-weight: 700
    top: -18px
    transform: rotate(15deg)
    text-align: center

  &__text
    font-size: 20px
    line-height: 32px
    text-align: left

</style>
