<template lang="pug">
  .fixed-back-button
    custom-button(theme="accent").fixed-back-button__button Назад к конструктору ↑
</template>

<script>
/* eslint-disable */
import CustomButton from '@/components/CustomButton.vue';


export default {
  components: {
    CustomButton
  }
}
</script>

<style scoped lang="sass">
.fixed-back-button
  position: fixed
  bottom: 0
  left: 0
  right: 0
  z-index: 2
  @media (max-width: 460px)
    padding: 0 20px


  &__button
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1)
    width: 300px
    margin: 0 auto 20px auto
    @media (max-width: 460px)
      width: 100%


</style>
