<template lang="pug">
  button.custom-button(:class="classes" anim="ripple")
    slot

</template>

<script>
/* eslint-disable */
export default {
  props: ['size', 'theme', 'disabled'],
  computed: {
    classes () {
      return [this.themeClass, this.disabled ? 'custom-button--disabled' : '']
    },
    themeClass () {
      return {
        outline: 'custom-button--outline',
        'outline-black': 'custom-button--outline-black',
        accent: 'custom-button--accent'
      }[this.theme]
    }
  }
};
</script>

<style scoped lang="sass">
.custom-button
  //--ripple-opacity: 1
  //--ripple-background: radial-gradient(circle at center, rebeccapurple, dodgerblue, olive, gold, orange, tomato)
  //--ripple-duration: 2000ms
  //--ripple-easing: cubic-bezier(0, .5, .5, 1)
  --ripple-background: black
  --ripple-opacity: 0.1
  --ripple-duration: 600ms

  width: 100%
  height: 60px
  border-radius: 4px
  font-size: 20px
  cursor: pointer
  border: none
  background: #efefef
  padding: 0 20px

  &--outline
    background: none
    border: 1px solid #fff
    color: #fff

  &--outline-black
    background: none
    border: 1px solid #000
    color: #000

    --ripple-opacity: 1
    --ripple-background: radial-gradient(circle at center, rebeccapurple, dodgerblue, olive, gold, orange, tomato)
    --ripple-duration: 2000ms
    --ripple-easing: cubic-bezier(0, .5, .5, 1)

    &.custom-button--disabled
      color: grey
      border: 1px solid grey

  &--accent
    background: linear-gradient(92.45deg, #8751D4 0%, #5883D4 100%)
    color: #fff

    &.custom-button--disabled
      background: linear-gradient(92.45deg, #69449F 0%, #49669F 100%)

  &--disabled
    pointer-events: none

</style>
