<template lang="pug">
  div#app
    router-view

</template>

<script>

export default {
  mounted () {

  }
}
</script>
