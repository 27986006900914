<template lang="pug">
  .item-modal
    .outter
    transition(name="slide-fade" appear)
      .item-modal__window
        image-modal.item-modal__image(
          :link="`/img/${image}`"
        )
          .item-modal__zoom-button
          .item-modal__badges
            badge(size="lg" v-if="$store.state.showItemsSizes") {{ item.type.category.sizes[item.selectedSizeIndex] }}
            badge(size="lg" v-if="item.type.badge") {{ item.type.badge }}
            badge(size="lg") {{ item.type.colors[item.selectedColorIndex].name }}
          .item-modal__badges.item-modal__badges--right
            badge(v-if="imageBadge" size="lg") {{ imageBadge }}
          .item-modal__image-controller.item-modal__image-controller--left(v-if="imageIndex > 0" @click.stop="clickBackImage")
          .item-modal__image-controller.item-modal__image-controller--right(v-if="imageIndex+1 < imagesLength" @click.stop="clickNextImage")

        .item-modal__content
          .item-modal__header
            .item-modal__title {{ item.type.name }}
            .item-modal__hide-button(@click="clickHideModal")
          .item-modal__parameters
            .item-modal__parameter(v-for="(parameter, index) in item.type.parameters" :key="index")
              .item-modal__parameter-name {{ parameter.name }}:
              .item-modal__parameter-value {{ parameter.value }}
          .item-modal__controllers
            custom-select(v-if="$store.state.showItemsSizes" label="Размер:" :options="item.type.sizes"
              @select="selectSize" :selected-index="item.selectedSizeIndex"
            )
            custom-select(label="Цвет:" :options="item.type.colors"
              @select="selectColor" :selected-index="item.selectedColorIndex"
            )

          custom-button(@click.native="clickHideModal") Свернуть
</template>

<script>
/* eslint-disable */

import CustomButton from '@/components/CustomButton.vue';
import CustomSelect from '@/components/CustomSelect.vue';
import ImageModal from '@/components/constructor/ImageModal.vue';
import Badge from '@/components/constructor/Badge.vue';

export default {
  components: {
    CustomButton,
    CustomSelect,
    ImageModal,
    Badge
  },
  data () {
    return {
      imageIndex: 0
    }
  },
  computed: {
    item () {
      return this.$store.state.selectedItem
    },
    imageBadge () {
      if (Array.isArray(this.item.type.colors[this.item.selectedColorIndex].img)) {
        if (typeof this.item.type.colors[this.item.selectedColorIndex].img[this.imageIndex] === "object") {
          return this.item.type.colors[this.item.selectedColorIndex].img[this.imageIndex].badge
        }
      }
      return null
    },
    image () {
      if (Array.isArray(this.item.type.colors[this.item.selectedColorIndex].img)) {
        if (typeof this.item.type.colors[this.item.selectedColorIndex].img[this.imageIndex] === "object") {
          return this.item.type.colors[this.item.selectedColorIndex].img[this.imageIndex].img
        } else {
          return this.item.type.colors[this.item.selectedColorIndex].img[this.imageIndex]
        }
      } else {
        return this.item.type.colors[this.item.selectedColorIndex].img
      }
    },
    imagesLength () {
      return Array.isArray(this.item.type.colors[this.item.selectedColorIndex].img) ? this.item.type.colors[this.item.selectedColorIndex].img.length : 1
    }
  },
  methods: {
    clickHideModal () {
      this.$store.commit('setShowItemModal', false)
    },
    selectSize (index) {
      this.$store.commit('setSelectedItemSizeIndex', index)
      this.$event( 'change_item_size', {
        'event_category': 'constructor'
      });
    },
    selectColor (index) {
      this.$store.commit('setSelectedItemColorIndex', index)
      this.$event( 'change_item_color', {
        'event_category': 'constructor'
      });
      this.imageIndex = 0
    },
    clickNextImage () {
      this.imageIndex++
    },
    clickBackImage () {
      this.imageIndex--
    }
  },
  mounted() {
    this.imageIndex = 0
  }
};
</script>

<style scoped lang="sass">
.item-modal
  &__window
    display: flex
    position: fixed
    flex-direction: column
    bottom: 0
    left: 0
    right: 0
    top: 0
    background: #fff
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.25)
    overflow: hidden
    z-index: 6
    transition: all 0.5s ease-out

    @media (min-width: 640px)
      border-radius: 20px

    @media (min-width: 640px)
      flex-direction: row
      max-width: 1082px
      max-height: 578px
      margin: auto

  &__content
    display: flex
    flex-direction: column
    padding: 0 20px 20px 20px
    @media (min-width: 640px)
      flex-grow: 1
      flex-basis: 0

  &__badges
    position: absolute
    display: flex
    margin: 20px 0 0 20px
    gap: 8px

    div
      box-shadow: 4px 4px 8px #0000000d

    &--right
      right: 0
      margin: 20px 20px 0 0


  &__zoom-button
    position: absolute
    left: 0
    right: 0
    bottom: 24px
    margin: auto
    width: 28px
    height: 27px
    opacity: .4
    background-size: contain
    background-image: url('~@/assets/zoom.svg')
    cursor: pointer

    &:hover
      opacity: .8

  &__header
    font-size: 24px
    font-weight: bold
    display: flex
    justify-content: space-between
    margin-top: 24px

  &__hide-button
    background-image: url('~@/assets/arrow-up.svg')
    transform: rotate(180deg)
    background-repeat: no-repeat
    background-position: center
    width: 26px
    height: auto
    cursor: pointer

  &__parameters
    font-size: 16px
    display: flex
    flex-direction: row
    margin: 24px 0

  &__parameter
    display: flex

    &-value
      margin-left: 8px

  &__image
    flex-grow: 1
    background-repeat: no-repeat
    background-size: cover
    background-position: center


  &__controllers
    margin-bottom: 20px
    display: flex
    flex-direction: column
    gap: 16px

    @media (min-width: 640px)
      margin-bottom: auto

  &__image-controller
    position: absolute
    width: 60px
    height: 60px
    background-color: rgba(255,255,255, 1)
    background-image: url('~@/assets/arrow-up.svg')
    background-repeat: no-repeat
    background-position: center 20px
    opacity: .6
    &:hover
      opacity: .8


    &--left
      left: 40px
      bottom: 40px
      transform: rotate(-90deg)


    &--right
      right: 40px
      bottom: 40px
      transform: rotate(90deg)







</style>
