<template lang="pug">
.mission(track-viewing="mission")
  .mission__subtitle Миссия Стафриума –
  h1.mission__title Помогать людям выглядеть стильно и опрятно, экономя их время
</template>

<script>
/* eslint-disable */

export default {
}
</script>

<style scoped lang="sass">
.mission
  &__subtitle
    font-size: 32px
    font-weight: bold
    margin-bottom: 24px
  &__title
    font-size: 64px
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000
    color: white
    font-weight: bold

    @media (max-width: 800px)
      font-size: 52px

</style>
