<template lang="pug">
 .my-sizes-modal
   .my-sizes-modal__header При добавлении
    |  предмета по умолчанию будут выставляться следующие размеры:
   .my-sizes-modal__content
    my-sizes-controllers.my-sizes-modal__controllers
   custom-button(@click.native="clickHideModal") Свернуть
</template>

<script>

/* eslint-disable */
import CustomButton from '@/components/CustomButton.vue';
import CustomSelect from '@/components/CustomSelect.vue';
import MySizesControllers from '@/components/constructor/MySizeControllers.vue';
import itemsCategories from './itemsCategories';


export default {
  components: {
    CustomButton,
    CustomSelect,
    MySizesControllers
  },
  data () {
    return {
      itemsCategories
    }
  },
  methods: {
    clickHideModal () {
      this.$store.commit('setShowMySizesModal', false)
    }
  }
};
</script>

<style scoped lang="sass">
.my-sizes-modal
  position: fixed
  bottom: 0
  left: 0
  right: 0
  background: #fff
  padding: 20px
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.25)
  border-radius: 12px 12px 0 0
  overflow: hidden
  z-index: 2
  transition: all 0.5s ease-out
  max-width: 480px
  margin: 0 auto

  &__header
    color: #8F8F8F
    text-align: center

  &__content
    display: flex
    flex-direction: column
    gap: 16px
    margin: 24px 0

  &__select
    flex-grow: 1

  &__controllers
    margin: 24px 0


</style>
