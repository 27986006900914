<template lang="pug">
  .hero-screen__wrapper
    .hero-screen__fabric
    .hero-screen
      .hero-screen__logos
      .hero-screen__helper.hero-screen__helper--top
        img(src="~@/assets/helper-arrow.svg").hero-screen__helper-arrow.hero-screen__helper-arrow--up
        | Мы уделили столько места нашей ткани, потому что мы ее фанаты
      .hero-screen__titles(track-viewing="hero_screen_titles")
        h1.hero-screen__title Премиальные
          br
          |  базовые вещи
        h1.hero-screen__title.hero-screen__title--outline-white против беготни
          br
          |  по масс-маркету
        h1.hero-screen__title.hero-screen__title--outline-blue против вещей “растягатусов”
        h1.hero-screen__title.hero-screen__title--outline-pink против вещей, разрушающихся
          br
          | после двух стирок
        .hero-screen__helper.hero-screen__helper--bottom
          img(src="~@/assets/helper-arrow.svg").hero-screen__helper-arrow.hero-screen__helper-arrow--down
          | Наш Бокс с фиксированной ценой, содержимое ты настраиваешь сам

    .hero-screen__box

</template>

<script>
/* eslint-disable */
import CustomButton from "@/components/CustomButton";

export default {
  components: {
    CustomButton,
  }
}
</script>

<style scoped lang="sass">
.hero-screen
  background-color: black
  min-height: 100vh
  position: relative
  text-align: left

  &__fabric
    position: absolute
    margin: 0 auto
    background-image: url('~@/assets/fabric-hero.png')
    height: 598px
    left: 0
    right: 0
    background-repeat: no-repeat
    background-position: top center
    background-size: cover

    z-index: 1

    @media (max-width: 480px)
      background-position: bottom center
      height: 680px
      background-image: url('~@/assets/fabric-hero-mobile.png')
    @media (min-width: 820px)
      background-size: contain




  &__titles
    line-height: 100px
    color: white
    position: relative
    padding-bottom: 460px
    padding-top: 680px


    @media (max-width: 820px)
      padding-top: 880px


  &__title
    font-size: 82px

    &--outline
      &-white
        color: black
        text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff
      &-blue
        color: black
        text-shadow: 1px 0 0 #5883D4, 0 -1px 0 #5883D4, 0 1px 0 #5883D4, -1px 0 0 #5883D4
      &-pink
        color: black
        text-shadow: 1px 0 0 #8751D4, 0 -1px 0 #8751D4, 0 1px 0 #8751D4, -1px 0 0 #8751D4

    @media (max-width: 820px)
      font-size: 40px
      line-height: 52px

    @media (max-width: 375px)
      font-size: 36px

    @media (max-width: 350px)
      font-size: 32px

  &__box
    position: absolute
    bottom: -175px
    left: 0
    right: 0
    margin: 0 auto
    width: 589px
    height: 382px
    background-size: contain
    background-image: url('~@/assets/box.png')
    background-repeat: no-repeat
    background-position: center

    @media (max-width: 820px)
      width: 100%

  &__helper
    position: absolute
    color: white
    font-size: 20px
    line-height: 28px
    display: flex
    gap: 40px

    @media (max-width: 820px)
      gap: 20px

    &--top
      width: 260px
      flex-direction: column
      top: 380px
      left: 80px
      z-index: 1
      @media (max-width: 820px)
        top: 600px
        left: unset
        right: 0
        width: 220px

    &--bottom
      width: 300px
      flex-direction: column-reverse
      bottom: 200px
      right: 0
      @media (max-width: 480px)
        bottom: 160px

    &-arrow
      margin-left: 80px
      max-width: 12px

      &--up
        @media (max-width: 820px)
          transform: scaleX(-1)

      &--down
        transform: rotate(208deg)

        @media (max-width: 820px)
          transform: rotate(185deg)
          margin-left: 78%

  &__logos
    position: absolute
    right: 0
    top: 680px
    width: 151px
    height: 96px
    background-image: url('~@/assets/logos-faded.svg')
    background-size: contain

    @media (max-width: 820px)
      top: 20px
      left: 0
      z-index: 1





</style>
