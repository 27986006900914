<template lang="pug">
.accordion
  .accordion__header(@click="clickHeader")
    .accordion__name {{ title }}
    .accordion__arrow(:style="{'transform': opened ? '' : 'rotate(180deg)'}")
  transition(name="fade")
    .accordion__content(v-if="opened")
      slot
</template>

<script>
/* eslint-disable */
export default {
  props: ['title'],
  computed: {
    opened () {
      return this.$parent.openedAccordionIndex === this.accordionIndex
    },
    accordionIndex () {
      return this.$parent.$children.findIndex(e => e._uid === this._uid)
    }
  },
  methods: {
    clickHeader () {
      this.$parent.setOpenedAccordion(this.accordionIndex)
    }
  }
};
</script>

<style scoped lang="sass">
.accordion
  margin-top: -1px

  &__header
    height: 68px
    display: flex
    justify-content: space-between
    padding: 0 20px
    background-color: #FBFBFB
    border-bottom: 1px solid #DCDCDC
    border-top: 1px solid #DCDCDC
    align-items: center
    font-size: 20px
    cursor: pointer


  &__arrow
    width: 26px
    height: 16px
    background-image: url('~@/assets/arrow-up.svg')
    transition: .2s

  &__content
    font-size: 16px
    line-height: 24px
    margin: 48px 0
</style>
