<template lang="pug">
  .content-modal(v-if="opened")
    .outter(@click="hide")
    transition(name="slide-fade" appear)
      .content-modal__window
        .content-modal__header
          .content-modal__title {{ title }}
          .content-modal__hide-button(@click="hide")
        .content-modal__content
          slot
</template>

<script>
/* eslint-disable */

import CustomButton from '@/components/CustomButton.vue';

export default {
  props: ['title'],
  data () {
    return {
      opened: false
    }
  },
  components: {
    CustomButton
  },
  methods: {
    show () {
      this.opened = true
    },
    hide () {
      this.opened = false
    }
  }
};
</script>

<style scoped lang="sass">
.content-modal
  text-align: left
  &__window
    display: flex
    position: fixed
    flex-direction: column
    bottom: 0
    left: 0
    right: 0
    top: 0
    background: #fff
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.25)
    overflow: hidden
    z-index: 6
    transition: all 0.5s ease-out

    @media (min-width: 640px)
      border-radius: 20px

    @media (min-width: 640px)
      flex-direction: column
      max-width: 1082px
      max-height: 90%
      margin: auto


  &__header
    font-size: 24px
    font-weight: bold
    display: flex
    justify-content: space-between
    margin: 24px 0
    padding: 0 20px

  &__hide-button
    background-image: url('~@/assets/arrow-up.svg')
    transform: rotate(180deg)
    background-repeat: no-repeat
    background-position: center
    width: 26px
    height: auto
    cursor: pointer

  &__content
    padding: 0 20px 40px 20px
    overflow: scroll
    @media (max-width: 640px)
      div:not(:first-child)
        margin-top: 40px

    @media (min-width: 640px)
      overflow: scroll
      display: flex
      flex-wrap: wrap
      align-content: stretch
      justify-content: space-between
      margin: 0 20px
      gap: 40px 20px

  &__bottom-button
    min-height: 60px
    width: unset
    margin: 20px



</style>
