<template lang="pug">
.review
  .review__avatar(:style="photoStyle" v-if="!hideAvatar")
    .review__social(v-if="instagram" @click="clickInstagram")
  template(v-else)
    .review__avatar-placeholder(style="min-width: 52px")
  .review__content
    .review__name(v-if="name") {{ name }}
    .review__text {{ text }}
      slot
    .review__comment-angle(v-if="!hideAvatar")

</template>

<script>
/* eslint-disable */

export default {
  props: ['avatar', 'name', 'text', 'photo', 'instagram', 'hideAvatar'],
  computed: {
    photoStyle () {
      return {
        'background-image': `url('${require('../assets/reviewers/'+this.photo)}')`
      }
    }
  },
  methods: {
    clickInstagram () {
      this.$event( 'go_instagram', {
        'event_category': 'reviews',
        'event_label': this.instagram,
      });
      window.open('https://instagram.com/' + this.instagram);
    }
  }
}
</script>

<style scoped lang="sass">
.review
  display: flex
  align-items: flex-end
  gap: 20px
  line-height: 28px


  &__avatar
    min-width: 52px
    height: 52px
    position: relative
    background-size: cover
    background-position: center
    border-radius: 40px


  &__social
    position: absolute
    background-image: url('~@/assets/instagram.svg')
    width: 20px
    height: 20px
    right: 0
    top: -12px
    background-color: white
    border-radius: 6px
    cursor: pointer

  &__content
    padding: 12px 20px 20px 20px
    box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.05)
    border-radius: 20px
    border: 1px solid rgba(0, 0, 0, 0.1)
    position: relative
    background: #fff

  &__text
    button
      margin-top: 12px

  &__name
    font-weight: 600
    color: #5B2398
    margin-bottom: 8px



  &__comment-angle
    width: 23px
    height: 21px
    background-image: url('~@/assets/comment_angle.svg')
    position: absolute
    bottom: 0
    left: -11px


</style>
