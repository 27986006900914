import { render, staticRenderFns } from "./FabricScreen.vue?vue&type=template&id=763f1440&scoped=true&lang=pug&"
var script = {}
import style0 from "./FabricScreen.vue?vue&type=style&index=0&id=763f1440&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "763f1440",
  null
  
)

export default component.exports