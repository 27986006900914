/* eslint-disable */
const itemsCategories = require('./itemsCategories')

module.exports = [
  {
    id: 1,
    name: 'Носки длинные',
    category: itemsCategories['socks'],
    sort: 4,
    sizes: itemsCategories['socks'].sizes,
    parameters: [
      {
        name: 'Материал',
        value: '100% плотный хлопок'
      }
    ],
    colors: [
      {
        name: 'Черный',
        color: '#151515',
        img: 'socks-long/black.jpg',
        thumb: 'socks-long/black__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Серый',
        color: '#a9a9a9',
        img: 'socks-long/grey.jpg',
        texture: 'socks-long/grey.jpg',
        thumb: 'socks-long/grey__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Белый',
        color: '#ececec',
        img: 'socks-long/white.jpg',
        thumb: 'socks-long/white__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Синий фактурный',
        color: '#807f99',
        img: 'socks-long/blue-textured.jpg',
        texture: 'socks-long/blue-textured.jpg',
        thumb: 'socks-long/blue-textured__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Голубой фактурный',
        color: '#b5b4d2',
        img: 'socks-long/light-blue-textured.jpg',
        texture: 'socks-long/light-blue-textured.jpg',
        thumb: 'socks-long/light-blue-textured__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Красный фактурный',
        color: '#ab828e',
        img: 'socks-long/red-textured.jpg',
        texture: 'socks-long/red-textured.jpg',
        thumb: 'socks-long/red-textured__thumb.jpg',
        avaliableSizes: 'all'
      }
    ],
  },
  {
    id: 2,
    name: 'Футболка Relax Fit',
    badge: 'Relax',
    sort: 3,
    category: itemsCategories['shirt'],
    parameters: [
      {
        name: 'Материал',
        value: '100% плотный хлопок'
      }
    ],
    sizes: itemsCategories['shirt'].sizes,
    colors: [
      {
        name: 'Черный',
        color: '#000',
        img: 'shirt-oversized/black.jpg',
        thumb: 'shirt-oversized/black__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Синий',
        color: '#858591',
        img: 'shirt-oversized/blue.jpg',
        thumb: 'shirt-oversized/blue__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Бежевый',
        color: '#C6B49C',
        img: 'shirt-oversized/sand.jpg',
        thumb: 'shirt-oversized/sand__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Белый',
        color: '#f5f4f5',
        img: 'shirt-oversized/white.jpg',
        thumb: 'shirt-oversized/white__thumb.jpg',
        avaliableSizes: 'all'
      },
    ],
  },
  {
    id: 3,
    name: 'Футболка Regular',
    badge: 'Regular',
    sort: 2,
    category: itemsCategories['shirt'],
    parameters: [
      {
        name: 'Материал',
        value: '100% плотный хлопок'
      }
    ],
    sizes: itemsCategories['shirt'].sizes,
    colors: [
      {
        name: 'Черный',
        color: '#000',
        img: 'shirt-regular/black.jpg',
        thumb: 'shirt-regular/black__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Бежевый',
        color: '#b0a089',
        img: 'shirt-regular/light-brown.jpg',
        thumb: 'shirt-regular/light-brown__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Серый',
        color: '#c7c2c4',
        img: 'shirt-regular/light-grey.jpg',
        thumb: 'shirt-regular/light-grey__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Белый',
        color: '#f5f4f5',
        img: 'shirt-regular/white.jpg',
        thumb: 'shirt-regular/white__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Пинк',
        color: '#c4b0b3',
        img: 'shirt-regular/pink.jpg',
        thumb: 'shirt-regular/pink__thumb.jpg',
        avaliableSizes: 'all'
      },
    ],
  },
  {
    id: 4,
    name: 'Трусы Шортиками',
    category: itemsCategories['pants'],
    sort: 6,
    parameters: [
      {
        name: 'Материал',
        value: '100% плотный хлопок'
      }
    ],
    sizes: itemsCategories['pants'].sizes,
    colors: [
      {
        name: 'Синяя сеточка',
        color: '#77859b',
        img: 'pants-boxers-free/blue-grid.jpg',
        texture: 'pants-boxers-free/blue-grid.jpg',
        thumb: 'pants-boxers-free/blue-grid__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Серый',
        color: '#d2d2d2',
        img: 'pants-boxers-free/grey.jpg',
        thumb: 'pants-boxers-free/grey__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Светлый голубой',
        color: '#e2e7f7',
        img: 'pants-boxers-free/light-blue.jpg',
        thumb: 'pants-boxers-free/light-blue__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Белый',
        color: '#f5f4f5',
        img: 'pants-boxers-free/white.jpg',
        thumb: 'pants-boxers-free/white__thumb.jpg',
        avaliableSizes: 'all'
      },
    ],
  },
  {
    id: 5,
    name: 'Трусы Боксеры',
    sort: 5,
    category: itemsCategories['pants'],
    parameters: [
      {
        name: 'Материал',
        value: '100% плотный хлопок'
      }
    ],
    sizes: itemsCategories['pants'].sizes,
    colors: [
      {
        name: 'Черный',
        color: '#000',
        img: 'pants-boxers/black.jpg',
        thumb: 'pants-boxers/black__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Синий',
        color: '#2b2b43',
        img: 'pants-boxers/blue.jpg',
        thumb: 'pants-boxers/blue__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Серый',
        color: '#c1c1c1',
        img: 'pants-boxers/grey.jpg',
        thumb: 'pants-boxers/grey__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Белый',
        color: '#e7e7e7',
        img: 'pants-boxers/white.jpg',
        thumb: 'pants-boxers/white__thumb.jpg',
        avaliableSizes: 'all'
      },
    ],
  },
  {
    id: 6,
    name: 'Худи',
    sort: 1,
    category: itemsCategories['hoodie'],
    parameters: [
      {
        name: 'Материал',
        value: '100% плотный хлопок'
      }
    ],
    sizes: itemsCategories['hoodie'].sizes,
    colors: [
      {
        name: 'Черный',
        color: '#000',
        img: 'hoodies/black.jpg',
        thumb: 'hoodies/black__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Зеленый',
        color: '#607e7e',
        img: 'hoodies/green.jpg',
        thumb: 'hoodies/green__thumb.jpg',
        avaliableSizes: 'all'
      },
      {
        name: 'Синий',
        color: '#3d4b58',
        img: 'hoodies/blue.jpg',
        thumb: 'hoodies/blue__thumb.jpg',
        avaliableSizes: 'all'
      },
    ],
  }
]
