import Vue from 'vue';
import VueRouter from 'vue-router';
import VueGtag from 'vue-gtag';
import VueYandexMetrika from 'vue-yandex-metrika';
import axios from 'axios';
import store from './vuex/store';
import IndexView from './pages/Index.vue';
import QuizView from './pages/Quiz.vue';
import App from './App.vue';

const generateSessionId = () => { // Public Domain/MIT
  var d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
    d += performance.now(); //use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}


Vue.use(VueRouter)

const routes = [
  { path: '/', component: IndexView },
  { path: '/quiz', component: QuizView },
]
const router = new VueRouter({
  mode: 'history',
  hash: false,

  routes // short for `routes: routes`
})

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

Vue.use(VueGtag, { config:
    {
      id: 'UA-209909448-1',
      // params: {
      //   send_page_view: false
      // }
    }
});

Vue.$gtag.set(
  {
    'custom_map':
      {
        'dimension1': 'client_id',
        'dimension2': 'timestamp',
        'dimension3': 'session_id'
      }
  }
);
Vue.$gtag.pageview({page_path: router.currentRoute.path, session_id: generateSessionId()})

// console.log(router.currentRoute.path)

Vue.prototype.$event = (action, params) => {
  if (params) {
    params.timestamp = Date.now()
  } else {
    params = {timestamp: Date.now()}
  }
  Vue.$gtag.event(action, params)
}

Vue.use(VueYandexMetrika, {
  id: 85968936,
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  },
  // other options
});



new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
