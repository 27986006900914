<template lang="pug">
  .accordion-group
    slot

</template>

<script>
/* eslint-disable */
export default {
  data () {
    return {
      openedAccordionIndex: 0
    }
  },
  methods: {
    setOpenedAccordion (index) {
      if (this.openedAccordionIndex === index)
        return this.openedAccordionIndex = -1
      this.openedAccordionIndex = index
    }
  }
};
</script>

<style scoped lang="sass">

</style>
