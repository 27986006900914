<template lang="pug">
  .custom-select
    .custom-select__label {{ label }}
    .custom-select__options
      template(v-for="(option, index) in options")
        .custom-select__option(
          v-if="typeof option === 'string'"
          :key="index"
          :class="{'custom-select__option--active': index === selectedIndex}"
          @click="$emit('select', index)"
        ) {{ option }}
        .custom-select__option.custom-select__option--color(
          v-else
          :key="index"
          :style="optionStyle(option)"
          :class="{'custom-select__option--color--active': index === selectedIndex}"
          @click="$emit('select', index)"
        )

</template>

<script>
/* eslint-disable */
export default {
  props: {
    'label': String,
    'options': Array,
    'selectedIndex': {
      type: Number,
      default: 0
    }
  },
  methods: {
    optionStyle (option) {
      return option.texture ?
        {'background-image': `url('${require('../assets/textures/' + option.texture)}')`}
        : {'background-color': option.color}
    }
  }
};
</script>

<style scoped lang="sass">
.custom-select
  display: flex
  justify-content: space-between

  &__options
    display: flex

  &__option
    padding: 8px 12px
    border: 1px solid #000
    margin-left: 12px
    border-radius: 4px
    min-height: 38px
    min-width: 38px
    box-sizing: border-box
    cursor: pointer
    background-size: contain

    &--color
      border: none
      &--active
        border: 1px solid #000


    &--active
      color: #ffffff
      background: #000

  &__label
    display: flex
    align-items: center
</style>
