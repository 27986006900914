<template lang="pug">
.helper(v-if="show")
  .helper__polygon
  .helper__content
    .helper__text
      slot
    .helper__close(@click="clickClose")

</template>

<script>
/* eslint-disable */
export default {
  data () {
    return {
      show: true
    }
  },
  methods: {
    clickClose () {
      this.show = false
      this.$emit('close')
    }
  },
  props: ['number']
};
</script>

<style scoped lang="sass">

.helper
  position: absolute
  height: 1px
  display: flex
  flex-direction: column-reverse
  z-index: 2
  top: -1px

  &__content
    background-color: rgba(0, 0, 0, .9)
    padding: 20px
    color: #fff
    font-size: 16px
    line-height: 20px
    border-radius: 12px
    display: flex
    gap: 20px
    text-align: left
    align-items: center

  &__close
    min-width: 24px
    height: 24px
    background-image: url('~@/assets/close--white.svg')
    background-size: cover
    cursor: pointer

  &__polygon
    width: 0
    height: 0
    margin: 0 auto
    border-style: solid
    border-width: 20px 14px 0 14px
    border-color: rgba(0, 0, 0, .9) transparent transparent transparent

</style>
