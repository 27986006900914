/* eslint-disable */

module.exports = [
  {
    typeId: 6,
    selectedColorIndex: 1
  },
  {
    typeId: 2,
    selectedColorIndex: 2,
  },
  {
    typeId: 5,
    selectedColorIndex: 0,
  },
  {
    typeId: 1,
    selectedColorIndex: 0,
  },
  {
    typeId: 1,
    selectedColorIndex: 1,
  }
]
