<template lang="pug">
  .index-view
    calculation-modal(v-if="$store.state.showCalculationModal")
    // .hero-pattern
      .hero-pattern__image(ref="heropattern")
      .hero-pattern__comet
    .black-screen-wrapper
      .container.hero-screen
        hero-screen-black
    .container.constructor-screen(track-viewing="contructor_screen")
      .row.row--sm
        .column.about-bot
          .about-bot__description После успешной оплаты первого комплекта умный бот сможет экономить твое время на базовых вещах:
          .about-bot__messages
            message.about-bot__message.about-bot__message--right Нужна белая футболка, худи и носки
            message(direction="left").about-bot__message.about-bot__message--left
              .about-bot__set
                .about-bot__set-row
                  cloth(img-name="hoodies/blue__thumb.jpg")
                .about-bot__set-row
                  cloth(img-name="shirt-oversized/white__thumb.jpg")
                  .about-bot__socks
                    cloth(img-name="socks-long/white__thumb.jpg")
                    cloth(img-name="socks-long/white__thumb.jpg")
                    cloth(img-name="socks-long/white__thumb.jpg")
                    cloth(img-name="socks-long/white__thumb.jpg")
              .about-bot__bot-message Подредактировать, выбрать удобное время доставки и оплатить тут 👇
              .about-bot__bot-link персональная ссылка


        .column.hero-screen__constructor(ref="constructor")
          | <constructor @purchase="clickPurchase"></constructor>
    .black-screen-wrapper
      .container
        fabric-screen

    .container.mission__wrapper
      mission

    .container.workers
      h1.workers__title Наша команда
      team
    .container.reviews__wrapper
      h1.reviews__title Отзывы покупателей
      reviews

    .container.information
      .row
        delivery-return(track-viewing="delivery_return")

    .container.footer(track-viewing="footer")
      .row
        .column.footer__info-column
          .footer__contacts
            //.footer__contact
              .footer__contact-name Telegram:
              .footer__contact-adress @stuffrium
            .footer__contact
              .footer__contact-name Телефон:
              .footer__contact-adress +7 495 160 00 51
            .footer__contact
              .footer__contact-name Email:
              .footer__contact-adress stuffrium@yandex.ru
          .footer__copyright
            img(src="~@/assets/logo.svg").footer__logo
            .footer__copyright-text © Все права защищены, 2021

        .column.footer__dec-column
          .footer__comet
          .footer__pattern
</template>

<script>
/* eslint-disable */

import Constructor from '@/components/constructor/Constructor.vue';
import Benefit from '@/components/Benefit.vue';
import Message from '@/components/Message.vue';
import CustomButton from "@/components/CustomButton";
import CalculationModal from "@/components/CalculationModal";
import Reviews from "@/components/content/Reviews.vue";
import Mission from "@/components/content/Mission.vue";
import Team from "@/components/content/Team.vue";
import DeliveryReturn from "@/components/content/DeliveryReturn.vue";
import HeroScreenBlack from "@/components/content/HeroScreenBlack.vue";
import FabricScreen from "@/components/content/FabricScreen.vue";
import Cloth from "@/components/constructor/Cloth.vue";
import FixedBackButton from "@/components/content/FixedBackButton.vue";

const isElementXPercentInViewport = function(el, percentVisible) {
  let
    rect = el.getBoundingClientRect(),
    windowHeight = (window.innerHeight || document.documentElement.clientHeight);

  return !(
    Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < percentVisible ||
    Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
  )
};

function throttle (callback, limit) {
  var waiting = false;                      // Initially, we're not waiting
  return function () {                      // We return a throttled function
    if (!waiting) {                       // If we're not waiting
      callback.apply(this, arguments);  // Execute users function
      waiting = true;                   // Prevent future invocations
      setTimeout(function () {          // After a period of time
        waiting = false;              // And allow future invocations
      }, limit);
    }
  }
}


export default {
  name: 'App',
  components: {
    CustomButton,
    Benefit,
    Constructor,
    Message,
    CalculationModal,
    Reviews,
    Mission,
    Team,
    DeliveryReturn,
    HeroScreenBlack,
    Cloth,
    FabricScreen,
    FixedBackButton
  },
  methods: {
    clickHowCalculated () {
      this.$store.commit('setShowCalculationModal', true)
      this.$event( 'click_how_calculated', {
        'event_category': 'benefits'
      });
    },
    clickMakeSet () {
      this.$refs['constructor'].scrollIntoView({behavior: 'smooth'})
      this.$event( 'click_make_set', {
        'event_category': 'hero_screen'
      });
    },
    clickPurchase () {
      window.fbq('track', 'AddToCart');
      this.$event( 'click_buy', {
        'event_category': 'constructor'
      });
      window.fbq('track', 'ViewContent');
    }
  },
  mounted() {
    let elementsToTrack = [];
    let inViewport = {}

    document.querySelectorAll('[track-viewing]').forEach((e) => {
      elementsToTrack.push({e: e, name: e.getAttribute('track-viewing')});
      inViewport[e.getAttribute('track-viewing')] = false
    });


    document.addEventListener('scroll', throttle(() => {
      elementsToTrack.forEach((e) => {
        if (inViewport[e.name] === false && isElementXPercentInViewport(e.e, 40)) {
          inViewport[e.name] = new Date()
        }
        if (inViewport[e.name] && !isElementXPercentInViewport(e.e, 40)) {
          const viewingTime = Math.round((new Date().getTime() - inViewport[e.name].getTime()) / 1000)
          if (viewingTime > 2) {
            this.$event( e.name, {
              'event_category': 'view_content',
              'event_label': viewingTime
            });
          }
          inViewport[e.name] = false
        }
      })

        //
        // if (isInViewport === false && isElementXPercentInViewport(document.querySelector('.fabric-screen__content'), 40)) {
        //   isInViewport = new Date()
        //   console.log('scrolled to')
        // }
        // if (isInViewport && !isElementXPercentInViewport(document.querySelector('.fabric-screen__content'), 40)) {
        //   console.log((new Date().getTime() - isInViewport.getTime()) / 1000)
        //   isInViewport = false
        // }
      }, 500)
    );


    [].map.call(document.querySelectorAll('[anim="ripple"]'), el=> {
      el.addEventListener('click',e => {
        e = e.touches ? e.touches[0] : e;
        const r = el.getBoundingClientRect(), d = Math.sqrt(Math.pow(r.width,2)+Math.pow(r.height,2)) * 2;
        el.style.cssText = `--s: 0; --o: 1;`;  el.offsetTop;
        el.style.cssText = `--t: 1; --o: 0; --d: ${d}; --x:${e.clientX - r.left}; --y:${e.clientY - r.top};`
      })
    })
  }
};
</script>

<style lang="sass">


.container
  max-width: 1082px
  margin: 0 auto
  text-align: left
  padding: 0 40px

  @media (max-width: 480px)
    padding: 0 20px


.row
  display: flex
  flex-wrap: wrap
  gap: 80px

  &--sm
    gap: 254px
    flex-wrap: wrap-reverse

    @media (max-width: 1140px)
      gap: 80px

.column
  flex-grow: 1
  flex-basis: 0

.about-bot
  display: flex
  flex-direction: column
  gap: 40px

  &__set
    margin: 4px -8px 4px -8px
    &-row
      margin: 8px
      height: 120px
      display: flex
      gap: 8px

      & > div
        flex-basis: 100%

  &__description
    font-size: 20px
    line-height: 32px

  &__messages
    display: flex
    flex-direction: column
    gap: 28px

  &__message
    line-height: 24px
    box-shadow: 8px 8px 40px rgba(0, 0, 0, 0.1)
    &--left
      margin-right: 40px
    &--right
      margin-left: 40px

  &__socks
    display: flex
    flex-wrap: wrap
    gap: 8px

    & > div
      flex-basis: calc(50% - 4px)
      height: calc(50% - 4px) !important

  &__bot-link
    font-style: italic
    color: #5B2398
    margin-bottom: 12px
  &__bot-message
    margin-top: 20px
    margin-bottom: 12px

.benefits
  display: flex
  flex-direction: column
  justify-content: center
  min-width: 330px
  max-width: 400px

  &__link
    display: inline-block
    border-bottom: 1px dashed black
    cursor: pointer

.constructor-screen
  margin-top: 320px
  margin-bottom: 320px
  @media (max-width: 480px)
    margin-top: 120px
    margin-bottom: 160px



.hero-pattern
  width: 100%
  height: 220px
  position: relative
  overflow: hidden

  &__comet
    height: 132px
    width: 50%
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url('../assets/primary-pattern.jpg')
    background-size: contain
    border-top-left-radius: 200px
    border-bottom-left-radius: 200px
    position: absolute
    right: 0
    bottom: -70px
    z-index: 3

  &__image
    position: absolute
    width: calc(100% + 48px)
    height: calc(100% + 48px)
    background-image: url('../assets/main-pattern.svg')
    filter: blur(2px)
    animation: pulseblur 10s
    animation-iteration-count: infinite
    background-size: cover

.black-screen-wrapper
  background-color: black
  position: relative




@keyframes pulseblur
  0%
    filter: blur(.5px)
  20%
    filter: blur(4px)
  50%
    filter: blur(4px)
  100%
    filter: blur(.5px)

h1
  margin: 0
  font-size: 32px
  font-weight: bold

p
  font-size: 20px
  line-height: 32px

.outter
  position: fixed
  left: 0
  right: 0
  top: 0
  bottom: 0
  z-index: 5
  background-color: rgba(0, 0, 0, .5)

.black-screen
  margin-top: 220px
  background-color: #383838
  width: 100%
  height: 100vh
  color: #fff

  @media (max-width: 635px)
    height: auto
    margin-top: 60px




  .container, .row
    height: 100%

  .row
    align-items: center
    @media (max-width: 635px)
      padding: 80px 0 240px 0


.smart-subscribe
  text-align: left

  &__text
    margin-top: 52px

  &__description-column
    display: flex
    flex-direction: column
    min-width: 256px

  &__row
    position: relative
    @media (max-width: 675px)
      gap: 120px

  &__go-constructor
    position: absolute
    bottom: 80px
    right: 0
    left: 0
    display: flex
    justify-content: center
    align-items: center
    gap: 20px
    font-size: 20px
    cursor: pointer
    @media (min-width: 675px)
      font-size: 16px


    &-arrow
      width: 19px
      height: 18px
      background-image: url('../assets/thin-arrow-up.svg')



  &__messangers
    display: flex
    margin-top: 44px
    gap: 16px
    flex-wrap: wrap

  &__messanger
    border: 1px solid #ffffff
    border-radius: 99px
    padding: 4px 12px
    background-color: #383838
    font-size: 16px
    box-shadow: 2px 2px 4px #D6F37A
    white-space: nowrap

    @media (min-width: 635px)


      &:nth-child(1)
        transform: rotate(-11deg)
      &:nth-child(2)
        transform: rotate(-14deg)
      &:nth-child(3)
        transform: rotate(-8deg)
      &:nth-child(4)
        transform: rotate(-4deg)

  &__parallax-column
    height: 300px
    position: relative
    min-width: 220px

  &__messages
    position: absolute
    top: 50%
    transform: translateY(-45%)
    z-index: 2
    display: flex
    flex-direction: column
    align-items: flex-end
    flex-wrap: wrap

  &__message
    width: 54%
    transform: rotate(4deg)
    font-size: 14px
    min-width: 220px

    @media (max-width: 1060px)
      width: 80%


    &:not(:first-child)
      margin-top: 20px

    &-link
      font-style: italic
      font-size: 14px
      color: #5B2398

  &__helper
    position: absolute
    right: 43px
    top: -62px
    transform: rotate(14deg)
    &-arrow
      background-image: url('../assets/pencil-arrow.svg')
      width: 30px
      height: 52px
      margin: 12px auto 0 auto
      transform: rotate(23deg)


    &-text
      color: white

  &__comet
    position: absolute
    width: 40px
    height: 56px
    right: 130px
    top: 120px
    transform: rotate(-79deg) scaleX(4.5)
    filter: blur(14px)
    border-radius: 99px
    background-color: #B5DC4A
    z-index: 1

.hero-screen
  color: #000
  text-align: center

  @media (max-width: 300px)
    text-align: left


  &__constructor
    display: flex
    justify-content: center
    align-items: center
    & > div
      @media (min-width: 828px)
        margin-left: auto


.information
  margin-top: 160px
  margin-bottom: 400px

  &__accordions
    margin-top: 40px
    min-width: 320px

  &__delivery-prices
    margin-top: 40px

  &__delivery-price-block
    display: flex
    justify-content: space-between

    &:not(:first-child)
      margin-top: 20px

  &__delivery-price
    padding: 2px 8px
    border: 2px solid #B5DC4A
    border-radius: 99px



.footer
  &__contacts
    display: flex
    flex-direction: column
    gap: 16px
    margin-top: 40px

  &__contact
    display: flex
    justify-content: space-between

  &__copyright
    margin: 40px 0 40px 0
    display: flex
    justify-content: space-between
    align-items: center
    gap: 20px

  &__logo
    height: 16px

  &__dec-column
    position: relative
    min-width: 340px
    min-height: 80px

  &__info-column
    min-width: 300px

  &__pattern
    background-image: url('../assets/footer-pattern.svg')
    height: 100%
    background-size: cover

  &__copyright-text
    text-align: right


  &__comet
    position: absolute
    width: 20px
    height: 40px
    transform: rotate(-12deg) scaleX(7.5)
    filter: blur(14px)
    border-radius: 99px
    background: linear-gradient(11.45deg, #8751D4 0%, #5883D4 100%)
    z-index: 1
    left: 80px
    top: -80px

    @media (max-width: 1060px)
      display: none

.mission__wrapper
  margin-top: 220px
  margin-bottom: 220px

  @media (max-width: 720px)
    margin-top: 100px
    margin-bottom: 100px





.reviews__wrapper
  margin-top: 200px
  margin-bottom: 400px
  padding-top: 60px
  @media (max-width: 800px)
    margin-top: 80px
    margin-bottom: 80px

.reviews__title
  margin-bottom: 52px

.workers__title
  margin-bottom: 52px

// -------



#app
  font-family: Montserrat, Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #000

body, p
  margin: 0

.fade-enter-active // , .fade-leave-active
  transition: opacity .3s

.fade-enter, .fade-leave-to
  opacity: 0

//

.rich-fade-enter-active // , .rich-fade-leave-active
  transition: opacity 1s

.rich-fade-enter, .rich-fade-leave-to
  opacity: 0

//

.slide-fade-enter-active
  transition: all .2s ease

.slide-fade-leave-active
  transition: all .2s ease

.slide-fade-enter
  transform: translateY(1000px)
//   opacity: 0

.slide-fade-leave-to
  transform: translateY(1000px)



// messanger animation

.messanger-enter-active
  transition: all 1s ease

.messanger-leave-active
  transition: all 1s ease

.messanger-enter
  transform: translateY(20px)
  opacity: 0

.messanger-leave-to
  transform: translateY(20px)
  opacity: 0


[anim="ripple"]

  //  Customize effect by defining the following
  //  variables on the parent element
  //  --ripple-background: white
  //  --ripple-opacity: 0.3
  //  --ripple-duration: 600ms
  //  --ripple-easing: linear

  position: relative
  overflow: hidden

  &:before
    content: ''
    position: absolute
    display: block
    background: var(--ripple-background, white)
    border-radius: 50%
    pointer-events: none

    //  position and size
    top: calc(var(--y) * 1px)
    left: calc(var(--x) * 1px)
    width:  calc(var(--d) * 1px)
    height: calc(var(--d) * 1px)

    //  animated properties
    opacity: calc(var(--o, 1) * var(--ripple-opacity, 0.3))
    transition: calc(var(--t, 0) * var(--ripple-duration, 600ms)) var(--ripple-easing,linear)
    transform: translate(-50%, -50%) scale(var(--s, 1))
    transform-origin: center

@font-face
  font-family: 'Montserrat'
  src: local('Montserrat'), url('../assets/fonts/Montserrat-Regular.ttf')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Montserrat'
  src: local('Montserrat'), url('../assets/fonts/Montserrat-Bold.ttf')
  font-weight: 700
  font-style: bold

@font-face
  font-family: 'Montserrat'
  src: local('Montserrat'), url('../assets/fonts/Montserrat-Medium.ttf')
  font-weight: 600
  font-style: normal


</style>
