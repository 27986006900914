<template lang="pug">
  .message
    slot
    .message__angle(:class="{'message__angle--left': direction === 'left'}")

</template>

<script>
export default {
  props: ['direction']
}

</script>

<style scoped lang="sass">
.message
  position: relative
  padding: 12px 20px
  background: #ffffff
  border-radius: 20px
  color: #000

  &__angle
    width: 23px
    height: 22px
    position: absolute
    bottom: 0
    right: -10px
    background-image: url('~@/assets/message-angle.svg')

    &--left
      left: -10px
      transform: scaleX(-1)

</style>
