<template lang="pug">
  .hero-screen__wrapper
    h1.hero-screen__title Персональный комплект базовых вещей дешевле, чем в масс-маркете
    custom-button(
      theme="outline-black"
      @click.native="clickMakeSet"
    ).hero-screen__button Собрать свой комплект
    .hero-screen__box
</template>

<script>
/* eslint-disable */
import CustomButton from "@/components/CustomButton";

export default {
  components: {
    CustomButton,
  },
  methods: {
    clickMakeSet () {
      this.$emit('click')
    },
  }
}
</script>

<style scoped lang="sass">
.hero-screen
  &__wrapper
    display: flex
    flex-direction: column
    align-items: center
    height: 100vh
    justify-content: center
    margin-bottom: 80px

  &__box
    width: 100%
    height: 300px
    background-image: url('~@/assets/box.jpg')
    background-size: contain
    background-repeat: no-repeat
    background-position: center

  &__button
    width: auto !important
    margin-bottom: 60px

  &__title
    max-width: 730px
    margin: 60px auto 54px auto
    font-weight: 800

    @media (max-width: 460px)
      margin: 44px auto 80px auto



</style>
