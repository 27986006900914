<template lang="pug">
  .item(:class="[`h${h}`, `w${w}`, this.removed ? 'item--removed' : '']"
    :style="{'opacity': canShow ? '1' : '0'}"
  )
    .item__content
      // transition(name="fade")
      .item__badges
        badge.item__size-badge(v-if="!removed && $store.state.showItemsSizes") {{ size }}
        badge.item__additional-badge(v-if="!removed\
        && item && item.type.badge"
        ) {{ item.type.badge }}
      cloth(v-if="!removed"
        :style="{'pointer-events': dragging ? 'none' : 'all'}"
        :img-name="thumb"
        @click.native="clickItem"
      )
      .item__add-icon(v-show="removed" @click="clickAdd") +
        .item__add-item-helper(v-if="w*h >= 4") Добавить вещь
      transition(name="fade")
        .item__remove-button(v-if="!removed && $store.state.editMode" @click="clickRemove") ☓
</template>

<script>
/* eslint-disable */
import Cloth from '@/components/constructor/Cloth.vue';
import Badge from '@/components/constructor/Badge.vue';

export default {
  props: ['w', 'h', 'index', 'id'],
  components: {
    Cloth,
    Badge
  },
  data() {
    return {
      instance: null,
      muuriId: null,
      canShow: false
    }
  },
  computed: {
    size () {
      if (this.muuriId && this.item.type.sizes) {
        return this.item.type.sizes[this.item.selectedSizeIndex]
      }
    },
    item () {
      return this.$store.getters.constructorItem(this.muuriId)
    },
    removed () {
      if (this.muuriId) {
        return this.item.removed
      }
      return false
    },
    thumb () {
      if (this.muuriId) {

        return this.item.type.colors[this.item.selectedColorIndex].thumb
      }
      return null
    },
    dragging () {
      return this.muuriId && this.muuriInstance._drag._isActive
    }
  },
  watch: {
    muuriId () {
      setTimeout(() => {
      this.canShow = true
      }, 0)
    }
  },
  methods: {
    clickRemove () {
      // console.log(this.$parent)
      this.$event( 'click_remove', {
        'event_category': 'constructor',
        'event_label': `${this.item.type.name} ${this.item.type.colors[this.item.selectedColorIndex].name}`
      });
      this.$parent.markItemAsRemoved(this.muuriId)
      // this.$store.commit('markItemAsRemoved', this.id)
    },
    clickAdd () {
      this.$store.commit('setShowAddItemModal', true)
      this.$event( 'click_add_item', {
        'event_category': 'constructor'
      });
    },
    clickItem () {
      this.$store.commit('setShowItemModal', true)
      this.$store.commit('setSelectedItem', this.item)
      this.$event( 'click_item', {
        'event_category': 'constructor'
      });
    },
    dragEnd (e) {
      console.log('de')
    }
  }
};
</script>

<style lang="sass">
.item
  display: block
  position: absolute
  width: calc(25% - 12px)
  height: 64px
  margin: 6px
  z-index: 1
  background: rgba(0, 0, 0, .85)
  border-radius: 6px


  &__content
    position: relative
    width: 100%
    height: 100%
    cursor: pointer




  &__remove-button
    width: 32px
    height: 32px
    background: rgba(0, 0, 0, .85)
    border-radius: 8px
    display: flex
    justify-content: center
    align-items: center
    color: #fff
    position: absolute
    top: -4px
    left: 0
    right: 0
    margin-left: auto
    margin-right: auto

  &--removed
    background: #222222
    border: 1px dashed #FFF
    box-sizing: border-box


  &__add-icon
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    font-size: 34px
    line-height: 24px
    color: #FFF

  &__add-item-helper
    font-size: 12px

  &__badges
    position: absolute
    top: 8px
    left: 8px
    display: flex
    gap: 4px



.item.w2
  width: calc(50% - 12px)


.item.w4
  width: calc(100% - 12px)


.item.h2
  height: 140px

.item.h4
  height: 292px

.item.h6
  height: 444px


.custom-content
  display: table-cell
  vertical-align: middle
  text-align: center
  background: palegoldenrod
  color: #666
  /*border: 2px solid;*/
  /*border-radius: 3px;*/



</style>
