<template lang="pug">
  .cloth(:style="{'background-image': `url('${image}`}")
</template>

<script>
/* eslint-disable */

export default {
  props: ['imgName'],
  computed: {
    image () {
      return this.imgName ? require('../../assets/img/'+this.imgName) : ''
    }
  },
};
</script>

<style scoped lang="sass">
.cloth
  border-radius: 6px
  overflow: hidden
  width: 100%
  height: 100%
  background-size: cover
</style>
