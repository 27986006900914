<template lang="pug">
  .workers
    .workers__workers(track-viewing="workers")
      worker(
        name="Максим Красилов"
        position="Основатель stuffrium.ru, разработчик"
        photo="max.jpg"
      )
      worker(
        name="Настя Прибылова"
        position="Главная по маркетингу 🤓"
        photo="nastya.jpg"
      )
      worker(
        name="Вероника Тен"
        position="Делает нам крутой дизайн 👉 👈"
        photo="veronica.jpg"
      )
      worker(
        name="Гриша Никонов"
        position="Главный по разработке и пошиву вещей ✍️"
        photo="grisha.jpg"
      )
</template>

<script>
/* eslint-disable */
import Worker from "@/components/Worker.vue";

export default {
  components: {
    Worker
  }
}
</script>

<style scoped lang="sass">
.workers
  &__title
    margin-bottom: 52px
  &__workers
    display: flex
    gap: 40px
    @media (max-width: 800px)
      flex-direction: column


</style>
