/* eslint-disable */

module.exports = {
  'socks': {
    name: 'Носки',
    defaultSizeIndex: 1,
    sizes: [
      '36-39',
      '40-43',
      '44-45',
    ],
    w: 1,
    h: 1
  },
  'pants': {
    name: 'Трусы',
    defaultSizeIndex: 1,
    sizes: [
      'S',
      'M',
      'L',
    ],
    w: 1,
    h: 2
  },
  'shirt': {
    name: 'Футболки',
    defaultSizeIndex: 1,
    sizes: [
      'S',
      'M',
      'L'
    ],
    w: 2,
    h: 2
  },
  'hoodie': {
    name: 'Худи',
    defaultSizeIndex: 1,
    sizes: [
      'S',
      'M',
      'L',
    ],
    w: 4,
    h: 2
  }
}
