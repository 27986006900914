<template lang="pug">
.quiz
  calculation-modal(v-if="$store.state.showCalculationModal")
  content-modal(title="Наша команда" ref="teamModal").team-modal
    team.team-modal__team
    mission.team-modal__mission
  content-modal(title="Отзывы покупателей" ref="reviewsModal").reviews-modal
    reviews
  content-modal(title="Информация" ref="deliveryReturnModal").reviews-modal
    delivery-return
  transition(name="rich-fade" appear)
    hero-screen(v-if="step === 0" @click="clickNextStep").quiz__hero-screen
  transition(name="rich-fade" appear)
    .quiz__top(v-if="step > 0")
      .quiz__progress-bar(v-if="step > 0")
        .quiz__progress(:style="{'width': progressPercent +'%'}")
      .quiz__header(v-if="step > 0")
        .quiz__step {{ stepInfo }}
        .quiz__step-description {{ stepDescription }}
  .quiz__content(v-if="step > 0")
    .quiz__info-buttons-viewport(v-show="step === 4")
      .quiz__info-buttons-fade
      .quiz__info-buttons-wrapper
        .quiz__info-buttons
          button.quiz__question-button.quiz__question-button--lg(@click="clickDeliveryReturnInfo") Доставка / Возврат 🛴
          button.quiz__question-button.quiz__question-button--lg(@click="clickWhyCheap") Почему это выгодно?
          button.quiz__question-button.quiz__question-button--lg(@click="clickReviews") Отзывы покупателей 🤗️
          button.quiz__question-button.quiz__question-button--lg(@click="clickTeam") Команда Стафриума ✊
    .quiz__constructor(
      v-if="step > 0"
      :class="{'hidden': !(step === 1 || step === 2 || step === 4)}"
      :style="{'padding-bottom': isModalOpen ? '320px' : '40px'}"
    )
      | <constructor :empty="true" :hide-buttons="!(step === 4)" :hide-onboarding="true" @purchase="clickPurchase"></constructor>
      .quiz__constructor-stopper(v-if="step === 2")
    template(v-if="step === 3")
      transition-group(name="messanger" appear).quiz__messages
        review(
          v-if="showMessages['1']"
          key="1"
          name="Алина из Стафриума"
          text="Привет! Давай я немного расскажу про Стафриум 😉"
          photo="alina.jpg"
        )
        review(
          v-if="showMessages['2']"
          key="2"
          text="Смотри, комплект вещей, который ты собрал в ТЦ будет стоить больше 6 тысяч рублей. Стафриум экономит деньги и время 😊"
          photo="alina.jpg"
        )
          button.quiz__question-button(@click="clickWhyCheap") Почему это выгодно? 👀
        review(
          v-if="showMessages['3']"
          key="3"
          text="Если хочешь заценить нашу команду  и миссию, нажимай:"
          photo="alina.jpg"
        )
          button.quiz__question-button(@click="clickTeam") Команда Стафриума ✊
        review(
          v-if="showMessages['4']"
          key="4"
          text="Если хочешь посмотреть отзывы:"
          photo="alina.jpg"
        )
          button.quiz__question-button(@click="clickReviews") Отзывы покупателей 🤗️

  transition(name="rich-fade" appear)
    .quiz__controllers(v-if="step !== 4 && step > 0")
      my-sizes-controllers.quiz__my-sizes-controllers(v-if="step === 2")
      custom-button(theme="outline-black" @click.native="clickNextStep" :disabled="step === 1 && isSomethingRemoved") {{ buttonText }}
</template>

<script>
/* eslint-disable */
import CustomButton from '@/components/CustomButton.vue';
import ContentModal from '@/components/ContentModal.vue';
import Constructor from '@/components/constructor/Constructor.vue';
import MySizesControllers from '@/components/constructor/MySizeControllers.vue';
import Review from '@/components/Review.vue';
import Mission from '@/components/content/Mission.vue';
import Team from '@/components/content/Team.vue';
import Reviews from '@/components/content/Reviews.vue';
import DeliveryReturn from '@/components/content/DeliveryReturn.vue';
import CalculationModal from '@/components/CalculationModal.vue';
import HeroScreen from '@/components/content/HeroScreen.vue';


export default {
  components: {
    CustomButton,
    Constructor,
    ContentModal,
    MySizesControllers,
    Review,
    Mission,
    Team,
    Reviews,
    DeliveryReturn,
    CalculationModal,
    HeroScreen
  },
  data () {
    return {
      step: 0,
      showMessages: {
        1: false,
        2: false,
        3: false,
        4: false
      }
    }
  },
  computed: {
    stepDescription () {
      return {
        1: 'Заполни комплект подходящими вещами',
        2: 'Выбери размеры, которые тебе подходят',
        3: 'Узнай про Стафриум немного подробнее 😎',
        4: 'Проверь, все ли верно и можно заказывать 🙃👇'
      }[this.step]
    },
    stepInfo () {
      return this.isLastStep ? 'Готово 👏' : `Шаг ${this.step} из 4`
    },
    isLastStep () {
      return this.step === 4
    },
    buttonText () {
      return {
        1: this.isSomethingRemoved ? 'Заполни комплект' : 'Сохранить и продолжить →',
        2: 'Дальше →',
        3: 'Все понятно →'
      }[this.step]
    },
    progressPercent () {
      return (100 / 4)* this.step
    },
    lastShownMessageIndex () {
      let index = -1
      Object.keys(this.showMessages).forEach((key) => {
        if (this.showMessages[key] && !this.showMessages[key+1])
          index = key
      })
      return +index
    },
    isModalOpen () {
      return this.$store.state.showMySizesModal || this.$store.state.showAddItemModal
    },
    isSomethingRemoved () {
      return this.$store.state.items.findIndex(item => {
        return item.removed
      }) > -1
    }
  },
  methods: {
    clickWhyCheap () {
      this.$event( 'click_why_cheap', {
        'event_category': 'quiz',
        'event_label': this.step
      });
      this.$store.commit('setShowCalculationModal', true)
    },
    clickTeam () {
      this.$event( 'click_team', {
        'event_category': 'quiz',
        'event_label': this.step
      });
      this.$refs.teamModal.show()
    },
    clickDeliveryReturnInfo () {
      this.$event( 'click_delivery_return', {
        'event_category': 'quiz',
        'event_label': this.step
      });
      this.$refs.deliveryReturnModal.show()
    },
    clickReviews () {
      this.$event( 'click_reviews', {
        'event_category': 'quiz',
        'event_label': this.step
      });
      this.$refs.reviewsModal.show()
    },
    clickNextStep() {
      this.$event( 'complete_step', {
        'event_category': 'quiz',
        'event_label': this.step
      });
      this.step++
      window.scrollTo({
        top: 0
      });

      if (this.step === 2) {
        this.$store.commit('setEditMode', false)
        this.$store.commit('setShowItemsSizes', true)

        this.$store.commit('setShowItemModal', false)
        this.$store.commit('setShowAddItemModal', false)
      }

      if (this.step === 3) {
        setTimeout(() => { this.showMessages['1'] = true }, 300)
        setTimeout(() => { this.showMessages['2'] = true }, 3200)
        setTimeout(() => { this.showMessages['3'] = true }, 6900)
        setTimeout(() => {
          this.showMessages['4'] = true
          if (this.step === 3) {
            this.$event( 'waited_messages', {
              'event_category': 'quiz'
            });
          }
        }, 8000)
      }
    },
    clickPurchase () {
      window.fbq('track', 'ViewContent');
      this.$event( 'click_buy', {
        'event_category': 'quiz'
      });
    },
  },
  mounted () {
    this.$store.commit('setEditMode', true)
    this.$store.commit('setShowItemsSizes', false)
  }
}
</script>

<style scoped lang="sass">
.quiz
  max-width: 440px
  margin: 0 auto

  &__hero-screen
    padding: 0 20px

  &__header
    display: flex
    flex-direction: column
    padding: 32px 20px
    gap: 12px

  &__progress-bar
    height: 8px
    background: #383838

  &__progress
    height: 100%
    transition: all 1.6s
    background: linear-gradient(270.51deg, #B5DC4A 0%, #D6F37A 100%)

  &__step-description
    font-weight: bold
    font-size: 20px

  &__content
    min-height: 100vh

  &__controllers
    position: fixed
    bottom: 0
    right: 0
    left: 0
    padding: 20px
    background-color: #ffffff
    z-index: 3
    max-width: 440px
    margin: 0 auto


  &__my-sizes-controllers
    margin-bottom: 20px

  &__messages
    text-align: left
    padding: 20px 20px 120px 20px
    min-height: 100vh
    display: flex
    flex-direction: column
    gap: 20px

  &__question-button
    display: block
    border: 1px solid #5B2398
    border-radius: 8px
    font-size: 14px
    height: 32px
    line-height: 28px
    padding: 0 16px
    background-color: #ffffff
    font-family: Montserrat, Avenir, Helvetica, Arial, sans-serif
    white-space: nowrap

    &--lg
      height: 48px
      // border-radius: 99px

  &__constructor
    transition: opacity 1.5s
    position: relative

    &-stopper
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      z-index: 2

  &__info-buttons
    display: flex
    gap: 12px
    padding-right: 20px
    &-viewport
      position: relative
      margin: 20px

    &-wrapper
      overflow: scroll

      position: relative
      &::-webkit-scrollbar
        display: none

    &-fade
      position: absolute
      right: 0
      top: 0
      bottom: 0
      width: 4px
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)
      z-index: 1




.hidden
  opacity: 0
  position: absolute
  visibility: hidden
  width: 100vw

.team-modal
  &__team
    margin-bottom: 120px
  &__mission
    padding-bottom: 120px
</style>
