/* eslint-disable */

import Vue from 'vue';
import Vuex from 'vuex';
import itemsCategories from '../components/constructor/itemsCategories.js';

Vue.use(Vuex);


export default new Vuex.Store({
  state: {
    items: [],
    editMode: false,
    showAddItemModal: false,
    showMySizesModal: false,
    showItemModal: false,
    showPurchaseModal: false,
    showCalculationModal: false,
    selectedItem: null,
    categories: itemsCategories,
    showItemsSizes: true
  },
  mutations: {
    markItemAsRemoved (state, muuriId) {
      // Vue.set(state.items.find(item => item.instance._id === muuriId), 'removed', true)
      state.items.find(item => item.muuriId === muuriId).removed = true
      // state.items[index].removed = true
    },
    // setMuuriId (state, {index, muuriId}) {
    //   state.items[index].muuriId = muuriId
    // },
    deleteItemsByMuuriIds (state, muuriIds) {
      console.log('deleteItemsByMuuriIds', muuriIds)
      state.items = state.items.filter(item => !muuriIds.includes(item.muuriId))
    },
    changeTypeColorAndUnmark (state, {type, selectedColorIndex, index}) {
      state.items[index].type = type
      state.items[index].selectedColorIndex = selectedColorIndex
      state.items[index].removed = false
      state.items[index].selectedSizeIndex = Object.values(state.categories).find(category => category.name === type.category.name).defaultSizeIndex
    },
    createItem (state, {selectedColorIndex, selectedSizeIndex, type, removed, muuriId}) {
      state.items.push({selectedColorIndex, selectedSizeIndex, type, removed, muuriId})
    },
    setEditMode (state, value) {
      state.editMode = value
    },
    setShowAddItemModal (state, value) {
      state.showAddItemModal = value
      state.showMySizesModal = false
    },
    setShowItemModal (state, value) {
      state.showItemModal = value
    },
    setShowMySizesModal (state, value) {
      state.showMySizesModal = value
      state.showAddItemModal = false
    },
    setShowPurchaseModal (state, value) {
      state.showPurchaseModal = value
    },
    setShowCalculationModal (state, value) {
      state.showCalculationModal = value
    },
    setSelectedItem (state, value) {
      state.selectedItem = value
    },
    setSelectedItemSizeIndex (state, value) {
      state.selectedItem.selectedSizeIndex = value
    },
    setSelectedItemColorIndex (state, value) {
      state.selectedItem.selectedColorIndex = value
    },
    setDefaultSizeByCategory (state, {categoryName, sizeIndex}) {
      state.categories[Object
        .keys(state.categories)
        .find(key => state.categories[key].name === categoryName)
      ].defaultSizeIndex = sizeIndex

      // Меняем размеры в комплекте
      state.items.forEach(item => {
        if (item.type.category.name === categoryName) {
          item.selectedSizeIndex = sizeIndex
        }
      })
    },
    setShowItemsSizes (state, value) {
      state.showItemsSizes = value
    }
  },
  getters: {
    constructorItem: state => muuriId => {
      return state.items.find(item => item.muuriId === muuriId)
    },
  },
});
