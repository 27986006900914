<template lang="pug">
  .calculation-modal
    .outter(@click="clickHideModal")
    transition(name="slide-fade" appear)
      .calculation-modal__window
        .calculation-modal__header
          .calculation-modal__title Посчитаем вместе 🤓
          .calculation-modal__hide-button(@click="clickHideModal")
        .calculation-modal__content
          comparing-item(
            title="Футболкус-растягатус"
            description="Тонкий хлопок, с резинками, которые \
            растягиваются уже через несколько стирок"
            price="В ТЦ от 500 ₽"
            undertext="Такие мы не продаем 😩"
            image="shirt_nebro.png"
            theme="red"
          )
          comparing-item(
            title="Футболкус-качествус"
            description="Плотный хлопок, который не теряет форму, \
            качественная резинка – стильная вещь сохранит свой вид надолго"
            price="В ТЦ от 1000 до 3000 ₽"
            undertext="Наш вариант 👍"
            image="shirt.png"
            summary="В комплект можно добавить 6 таких футболок"
          )
          comparing-item(
            title="Носки из Италии 😌"
            description="Тонкий, преимущественно синтетический материал, \
            который оставляет следы на коже"
            price="на Рынке от 50 ₽"
            undertext="Такие мы не продаем 😁"
            image="socks_nebro.png"
            theme="red"
          )
          comparing-item(
            title="Носки в рубчик"
            description="Хорошо облегающий качественный материал, который держит форму, \
            их проще стирать – носки служат дольше, а выглядят стильно"
            price="В ТЦ от 250 до 500 ₽"
            undertext="Наш вариант 👍 "
            image="socks.png"
            summary="В комплект можно добавить 24 пары таких носков"

          )
          comparing-item(
            title="Белье лоу-костер"
            description="Материал зачастую с синтетикой более 10% в составе. При стирке в горячей\
             воде ткань «садится» и деформируется. При носке возникает дискомфорт"
            price="от 100 ₽"
            undertext="Такие мы не продаем"
            image="pants_nebro.png"
            theme="red"
          )
          comparing-item(
            title="Белье из премиального материала"
            description="Мягкая ткань плотного плетения для абсолютного комфорта. Шортиками – \
            свободный силуэт, либо классические Боксеры выглядят стильно, а служат долго "
            price="В ТЦ от 500 до 2000 ₽"
            undertext="Наш вариант 👍"
            image="pants.png"
            summary="В комплект можно добавить 12 трусов"
          )
        // custom-button(@click.native="clickHideModal").calculation-modal__bottom-button Свернуть
</template>

<script>
/* eslint-disable */

import CustomButton from '@/components/CustomButton.vue';
import ComparingItem from '@/components/ComparingItem.vue';

export default {
  components: {
    ComparingItem,
    CustomButton
  },
  methods: {
    clickHideModal () {
      this.$store.commit('setShowCalculationModal', false)
    }
  }
};
</script>

<style scoped lang="sass">
.calculation-modal
  &__window
    display: flex
    position: fixed
    flex-direction: column
    bottom: 0
    left: 0
    right: 0
    top: 0
    background: #fff
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.25)
    overflow: hidden
    z-index: 6
    transition: all 0.5s ease-out

    @media (min-width: 640px)
      border-radius: 20px

    @media (min-width: 640px)
      flex-direction: column
      max-width: 1082px
      max-height: 90%
      margin: auto


  &__header
    font-size: 24px
    font-weight: bold
    display: flex
    justify-content: space-between
    margin: 24px 0
    padding: 0 20px

  &__hide-button
    background-image: url('~@/assets/arrow-up.svg')
    transform: rotate(180deg)
    background-repeat: no-repeat
    background-position: center
    width: 26px
    height: auto
    cursor: pointer

  &__content
    overflow: scroll
    padding-bottom: 20px
    @media (max-width: 640px)
      div:not(:first-child)
        margin-top: 40px

    @media (min-width: 640px)
      overflow: scroll
      display: flex
      flex-wrap: wrap
      align-content: stretch
      justify-content: space-between
      margin: 0 20px
      gap: 40px 20px

  &__bottom-button
    min-height: 60px
    width: unset
    margin: 20px



</style>
