<template lang="pug">
.worker
  .worker__photo(:style="photoStyle")
  .worker__content
    .worker__name {{ name }}
    .worker__position {{ position }}
</template>

<script>
/* eslint-disable */

export default {
  props: ['photo', 'name', 'position'],
  computed: {
    photoStyle () {
      return {'background-image': `url('${require('../assets/workers/'+this.photo)}')`}
    }
  }
}
</script>

<style scoped lang="sass">
.worker
  width: 100%
  &__photo
    width: 100%
    height: 220px
    background-size: cover
    background-position: center
    border-radius: 12px

    @media (max-width: 800px)
      height: 320px



  &__content
    margin-top: 24px

  &__name
    font-weight: bold
    font-size: 20px

  &__position
    font-size: 16px
    margin-top: 12px

</style>
