<template lang="pug">
.badge(:class="classes")
  slot
</template>

<script>
/* eslint-disable */

export default {
  props: ['size'],
  computed: {
    classes () {
      return {
        'lg': 'badge--lg'
      }[this.size]
    }
  }
};
</script>

<style scoped lang="sass">
.badge
  font-size: 12px
  background: #ffffff
  border-radius: 99px
  padding: 0 4px

  &--lg
    font-size: 16px
    padding: 4px 8px


</style>
