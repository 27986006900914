<template lang="pug">
.delivery-return.row
  .column
    h1 Доставка
    accordion-group.delivery-return__accordions
      accordion(
        title="Курьером"
        @click.native="clickAccordion('delivery', 'Курьером')"
      ) Ежедневно с 10:00 до 22:00. Заказы доставляются
        |  нашими пешими курьерами с 12:00 до 18:00 с понедельника по пятницу.
        br
        br
        | Вы сможете выбрать удобное для вас времени доставки.
        .delivery-return__delivery-prices
          .delivery-return__delivery-price-block
            .delivery-return__delivery-price-label В пределах МКАД
            .delivery-return__delivery-price бесплатно
          .delivery-return__delivery-price-block
            .delivery-return__delivery-price-label За МКАД (до 50 км)
            .delivery-return__delivery-price 100 рублей
      accordion(
        title="Pickpoint"
        @click.native="clickAccordion('delivery', 'Pickpoint')"
      )
        |Вы можете забрать комплект в постоматах Pickpoint.
        |Карта с расположением находится здесь:
        br
        br
        a(href="https://pickpoint.ru/postamats/") https://pickpoint.ru/postamats/
        br
        br
        |Москва: 3 рабочих дня
        br
        |Московская область: 4-7 рабочих дней
        br
        |Санкт-Петербург: 2-4 рабочих дня
        br
        |Екатеринбург: 2-5 рабочих дней
        br
        |Краснодар: 2-6 рабочих дней
        br
        |Другие города России: 2-11 рабочих дней
        .delivery-return__delivery-prices
          .delivery-return__delivery-price-block
            .delivery-return__delivery-price-label Стоимость доставки
            .delivery-return__delivery-price бесплатно
      accordion(
        title="Почта России"
        @click.native="clickAccordion('delivery', 'Почта России')"
      )
        |Москва: 3 рабочих дня
        br
        |Московская область: 4-7 рабочих дней
        br
        |Санкт-Петербург: 2-4 рабочих дня
        br
        |Екатеринбург: 2-5 рабочих дней
        br
        |Краснодар: 2-6 рабочих дней
        br
        |Другие города России: 2-11 рабочих дней
        .delivery-return__delivery-prices
          .delivery-return__delivery-price-block
            .delivery-return__delivery-price-label Стоимость доставки
            .delivery-return__delivery-price бесплатно
  .column
    h1 Возврат
    accordion-group.delivery-return__accordions
      accordion(
        title="PickPoint"
        @click.native="clickAccordion('return', 'Pickpoint')"
      )
        |1. Найдите ближайший к вам постамат или пункт выдачи, принимающий возврат
        a(href="http://pickpoint.ru/postamats")  на сайте PickPoint
        br
        |2. В постамате выберите вариант «Отправка заказов».
        br
        |3. Выберите магазин STUFFRIUM, укажите код отправки и следуйте инструкциям.
        br
        |4. Вам не потребуется оплачивать возврат.
      accordion(
        title="Курьером СДЭК"
        @click.native="clickAccordion('return', 'Курьером СДЭК')"
      )
        |1. Обратитесь по телефону 8-800-250-19-30 (бесплатно), чтобы заказать
        |возврат через СДЭК.
        br
        |2. Курьер СДЭК заберет посылку по указанному вами адресу.
        br
        |3. За отправку возвращаемого товара платить не нужно.
      accordion(
        title="Пункт выдачи СДЭК"
        @click.native="clickAccordion('return', 'Пункт выдачи СДЭК')"
      )
        |1. Найдите ближайший к вам пункт выдачи
        a(href="https://cdek.by/contacts/city-list.html")  на сайте СДЭК
        br
        |2. В офисе сообщите сотруднику о возврате товара STUFFRIUM.
        br
        |3. За отправку возвращаемого товара платить не нужно.
</template>

<script>
/* eslint-disable */
import Accordion from '@/components/Accordion';
import AccordionGroup from '@/components/AccordionGroup.vue';

export default {
  components: {
    AccordionGroup,
    Accordion
  },
  methods: {
    clickAccordion (category, label) {
      this.$event( 'click_accordion', {
        'event_category': category,
        'event_label': label
      });
    },
  }
}
</script>

<style scoped lang="sass">
.delivery-return
  &__accordions
    margin-top: 40px
    min-width: 320px

  &__delivery-prices
    margin-top: 40px

  &__delivery-price-block
    display: flex
    justify-content: space-between

    &:not(:first-child)
      margin-top: 20px

  &__delivery-price
    padding: 2px 8px
    border: 2px solid #5926A4
    border-radius: 99px

</style>
