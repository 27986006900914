<template lang="pug">
.purchase-modal
  .outter(@click="clickHideModal")
  transition(name="slide-fade" appear)
    .purchase-modal__window
      .purchase-modal__header {{ headerText }}
        .purchase-modal__hide-button(@click="clickHideModal" v-if="step === 1")
      form.purchase-modal__content(@submit.prevent="submit" v-if="step === 1")
        .purchase-modal__message Заполни несколько полей и мы очень скоро доставим твой комплект  🙃👇
        .purchase-modal__inputs
          input(type="text" placeholder="Имя"
            required v-model="name").purchase-modal__input
          input(type="text" placeholder="+ 7 999 999 99 99"
            required v-model="phone").purchase-modal__input

          select.purchase-modal__select(v-model="delivery")
            option(value="1") Доставка курьером в Москве
            option(value="2") Доставка курьером за МКАД (50 км)
            option(value="3") Доставка через PickPoint
            option(value="4") Доставка почтой России
          .purchase-modal__delivery-price-block
            .purchase-modal__delivery-price-label Стоимость доставки
            .purchase-modal__delivery-price {{ +delivery === 2 ? '100 рублей' : 'бесплатно' }}

        .purchase-modal__current-step шаг 1 из 2
        custom-button(theme="accent").purchase-modal__next-button Перейти к оплате
      .purchase-modal__excuse-screen(v-else)
        .purchase-modal__excuse-text Наша команда проводит маркетинговые исследования,
          |  и к сожалению, мы не сможем обслужить твой заказ 😫
          br
          br
          strong Мы дарим тебе персональную скидку 10% на первую покупку
            |и обязательно свяжемся с тобой, когда
            |сервис начнет работать.
          |  Скидка привязана к контактам
          br
          br
          |Спасибо проявленное внимание!
        custom-button(theme="accent"
          @click.native="clickHideModal"
        ).purchase-modal__excuse-button Понятно

</template>

<script>
/* eslint-disable */

import CustomButton from '@/components/CustomButton.vue';

export default {
  components: {
    CustomButton,
  },
  data () {
    return {
      step: 1,
      name: '',
      phone: '',
      delivery: 1
    }
  },
  computed: {
    headerText () {
      return this.step === 1 ? 'Отличный выбор!' : 'В данный момент сервис не работает :('
    },
    selectedItemsTextArray () {
      return this.$store.state.items.reduce((selectedItemsTextArray, item) => {
        if (!item.removed) {
          selectedItemsTextArray.push(`${item.type.name} ${item.type.colors[item.selectedColorIndex].name} ${item.type.sizes[item.selectedSizeIndex]}`)
        }
        return selectedItemsTextArray
      }, [])
    }
  },
  methods: {
    clickHideModal () {
      this.$store.commit('setShowPurchaseModal', false)
    },
    submit () {
      this.step++
      this.$event( 'submit_go_ordering', {
        'event_category': 'constructor'
      });
      window.fbq('track', 'Lead');
      this.$axios.post('/api/request', {
        name: this.name,
        phone: this.phone,
        items: this.selectedItemsTextArray
      })
    }
  }
};
</script>

<style scoped lang="sass">
.purchase-modal
  &__window
    display: flex
    position: fixed
    flex-direction: column
    bottom: 0
    left: 0
    right: 0
    top: 0
    background: #fff
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.25)
    overflow: hidden
    z-index: 6
    transition: all 0.5s ease-out
    padding: 20px
    text-align: left


    @media (min-width: 635px)
      max-width: 542px
      max-height: 540px
      margin: auto
      padding: 40px
      border-radius: 20px

  &__content
    display: flex
    flex-direction: column
    height: 100%

  &__header
    font-size: 24px
    font-weight: bold
    display: flex
    justify-content: space-between

  &__hide-button
    background-image: url('~@/assets/arrow-up.svg')

    transform: rotate(180deg)
    background-repeat: no-repeat
    background-position: center
    width: 26px
    height: auto
    cursor: pointer
    @media (min-width: 635px)
      background-image: url('~@/assets/close.svg')


  &__message
    margin-top: 32px

  &__inputs
    display: flex
    flex-direction: column
    gap: 20px
    margin-top: 40px

  &__input, &__select
    height: 62px
    padding: 0 20px
    font-size: 16px
    border-radius: 4px
    box-sizing: border-box
    border: 1px solid #CACACA

  &__select
    -webkit-appearance: none
    -moz-appearance: none
    background: transparent
    background-image: url('~@/assets/arrow-down--sm.svg')
    background-repeat: no-repeat
    background-position: right 20px center

  &__current-step
    text-align: center
    margin-top: auto
    margin-bottom: 16px
    color: #AAAAAA

  &__excuse-screen
    height: 100%
    display: flex
    flex-direction: column

  &__excuse-text
    font-size: 20px
    margin-top: 32px

  &__excuse-button
    margin-top: auto

  &__delivery-price-block
    display: flex
    justify-content: space-between

    &:not(:first-child)
      margin-top: 16px

  &__delivery-price
    padding: 2px 8px
    border: 2px solid #5926A4
    border-radius: 99px


</style>
