<template lang="pug">
.my-sizes-controllers
  custom-select.my-sizes-controllers__select(
    v-for="(category, property, index) in itemsCategories"
    :key="index"
    :label="category.name + ':'"
    :options="sizesByCategory(category.name)"
    :selected-index="defaultSizeIndexByCategory(category.name)"
    @select="changeDefaultSizeByCategory($event, category.name)"
  )
</template>

<script>

/* eslint-disable */
import CustomSelect from '@/components/CustomSelect.vue';
import itemsCategories from './itemsCategories';


export default {
  components: {
    CustomSelect,
  },
  data () {
    return {
      itemsCategories
    }
  },
  methods: {
    sizesByCategory(categoryName) {
      return Object.values(this.$store.state.categories).find(category => category.name === categoryName).sizes
    },
    changeDefaultSizeByCategory(sizeIndex, categoryName) {
      this.$store.commit('setDefaultSizeByCategory', {categoryName, sizeIndex})
    },
  },
  computed: {
    defaultSizeIndexByCategory () {
      return (categoryName) => {
        return this.$store.state.categories[Object
          .keys(this.$store.state.categories)
          .find(key => this.$store.state.categories[key].name === categoryName)
          ].defaultSizeIndex
      }
    }
  }
};
</script>

<style scoped lang="sass">

.my-sizes-controllers
  display: flex
  flex-direction: column
  gap: 16px

  &__select
    flex-grow: 1


</style>
