<template lang="pug">
.image-modal
  .image-modal__image(:style="{'background-image':\
          `url('${image}')`}"
    @click="showModal = true"
  )
    slot
  .image-modal__modal-wrapper(v-if="showModal")
    .outter(@click="clickHideModal")
    transition(name="fade" appear)
      .image-modal__plate
        .image-modal__hide-button(@click="clickHideModal")
        img.image-modal__modal-image(:src="image")
</template>

<script>
/* eslint-disable */


export default {
  props: ['link'],
  data () {
    return {
      showModal: false
    }
  },
  computed: {
    image () {
      return require('../../assets'+this.link)
    }
  },
  methods: {
    clickHideModal () {
      this.showModal = false
    }
  }
};
</script>

<style scoped lang="sass">
.image-modal
  &__plate
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 2000
    display: flex
    align-items: center
    justify-content: center
    pointer-events: none

  &__hide-button
    position: absolute
    right: 40px
    top: 40px
    background-image: url('~@/assets/close--white.svg')
    background-repeat: no-repeat
    background-position: center
    width: 26px
    height: 26px
    cursor: pointer
    pointer-events: all

  &__image
    background-position: center
    background-size: cover
    width: 100%
    height: 100%
    cursor: pointer
    position: relative

  &__modal-image
    z-index: 2001
    max-width: 90%
    max-height: 90%
    pointer-events: all


</style>
